import { ThemeOptions, createTheme } from "@mui/material/styles"
import palette from "./palette"
import typography from "./typography"

const theme: ThemeOptions = {
  typography: typography,
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 545, // >544px mobile view
      md: 769, // >768px laptop view
      lg: 1025, // >1024px desktop view
      xl: 1536,
    },
  },
}
export const lightTheme = createTheme({
  palette: palette.lightPalette,
  ...theme,
})
export const darkTheme = createTheme({
  palette: palette.darkPalette,
  ...theme,
})
