import { Box, Tooltip, Typography } from "@mui/material"
import React, { ReactElement, useRef, lazy } from "react"
import { shortenAddress } from "../utils/shortenAddress"
import { useTranslation } from "react-i18next"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import Button from "./Button"
import { useBoolean } from "src/hooks/useBoolean"
import { SUPPORTED_WALLETS } from "../constants"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "src/state"
import { updateWalletConnector } from "src/state/user"
import LogoutIcon from "@mui/icons-material/Logout"
import Text from "./Text"
import { useENSName } from "src/hooks/useENSName"
const ConnectWalletModal = lazy(() => import("./ConnectWalletModal"))
const WrongNetworkModal = lazy(() => import("./WrongNetworkModal"))

interface Props {
  fullWidth?: boolean
}

const Web3Status = ({ fullWidth }: Props): ReactElement => {
  const { t } = useTranslation()
  const { deactivate, account, error } = useWeb3React()
  const dispatch = useDispatch()
  const [showModal, openModal, closeModal] = useBoolean(false)
  const connectedWallet = useSelector(
    (state: AppState) => state.user.connectedWallet,
  )
  const wallet = connectedWallet ? SUPPORTED_WALLETS[connectedWallet] : null
  const [showWrongNetworkModal, openWrongNetworkModal, closeWrongNetworkModal] =
    useBoolean(false)
  const [showDisconnectTooltip, openDisconnectTooltip, closeDisconnectTooltip] =
    useBoolean(false)

  const { ensName } = useENSName(account)

  const container = useRef<Element>()

  return (
    <>
      <Box
        data-testid="walletStatusContainer"
        position="relative"
        onMouseEnter={openDisconnectTooltip}
        onMouseLeave={closeDisconnectTooltip}
        ref={container}
      >
        <Button
          onClick={() => {
            if (error && error instanceof UnsupportedChainIdError) {
              closeDisconnectTooltip()
              openWrongNetworkModal()
            } else if (account) {
              openDisconnectTooltip()
            } else {
              closeDisconnectTooltip()
              openModal()
            }
          }}
          data-testid="accountDetailButton"
          endIcon={
            account && wallet?.icon && <img height="22px" src={wallet.icon} />
          }
          size="small"
          fullWidth={fullWidth}
        >
          {error && error instanceof UnsupportedChainIdError && (
            <ErrorOutlineIcon
              sx={{ marginRight: 0.5, marginTop: "1px" }}
              fontSize="small"
            />
          )}
          <Typography
            variant="body1"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {error && error instanceof UnsupportedChainIdError
              ? t("Wrong Network")
              : ensName ?? (account ? shortenAddress(account) : t("Connect"))}
          </Typography>
        </Button>
        {account && (
          <Tooltip
            PopperProps={{
              anchorEl: container.current,
            }}
            arrow={false}
            open={showDisconnectTooltip}
            title={
              <Box
                display="flex"
                alignItems="center"
                p={1}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deactivate()
                  dispatch(updateWalletConnector(null))
                  closeDisconnectTooltip()
                }}
              >
                <Text mr={3}>{t("Disconnect")}</Text>
                <LogoutIcon />
              </Box>
            }
          >
            <Box />
          </Tooltip>
        )}
      </Box>
      {showModal && <ConnectWalletModal onClose={closeModal} />}
      {showWrongNetworkModal && (
        <WrongNetworkModal onClose={closeWrongNetworkModal} />
      )}
    </>
  )
}

export default Web3Status
