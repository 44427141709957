import { AppBar, Box, Hidden, IconButton, Stack, Toolbar } from "@mui/material"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import React, { ReactElement } from "react"
import SiteSettingsMenu, { ActiveTabType } from "./SiteSettingsMenu"
import { Menu as MenuIcon } from "@mui/icons-material"
import FerroLogo from "./FerroLogo"
import Web3Status from "./Web3Status"
import classNames from "classnames"
import styles from "./TopMenu.module.scss"
import { useTranslation } from "react-i18next"
import { FerroBuyMenu } from "./FerroBuyAction"

function TopMenu(): ReactElement {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const activeTab = pathname.split("/")[1]?.toUpperCase() as ActiveTabType

  const handleSettingMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ bgcolor: "#000", transition: "none" }}
    >
      <Toolbar
        data-testid="topMenuContainer"
        className={styles.topMenu}
        sx={{ m: { md: 1 } }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="40px"
        >
          <Box flex={{ xl: 1 }}>
            <FerroLogo onClick={() => navigate(`/`)} />
          </Box>
          <Hidden mdDown>
            <Stack
              flex={1}
              direction="row"
              spacing={{ md: 2, lg: 3, xl: 5 }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <NavLink
                data-testid="swapNavLink"
                to="/swap"
                className={classNames(styles.link, {
                  [styles.active]: activeTab === "SWAP",
                })}
              >
                {t("SWAP")}
              </NavLink>
              <NavLink
                data-testid="poolsNavLink"
                to="/pools"
                className={classNames(styles.link, {
                  [styles.active]: activeTab === "POOLS",
                })}
              >
                {t("POOLS")}
              </NavLink>
              <NavLink
                data-testid="stakeNavLink"
                to="/stake"
                className={classNames(styles.link, {
                  [styles.active]: activeTab === "STAKE",
                })}
              >
                {t("STAKE")}
              </NavLink>
              <NavLink
                to="/rewards"
                className={classNames(styles.link, {
                  [styles.active]: activeTab === "REWARDS",
                })}
              >
                REWARDS
              </NavLink>
              <Hidden lgDown>
                <Box width="1px" height="30px" bgcolor="#505050" />
                <FerroBuyMenu />
                <Web3Status />
              </Hidden>
            </Stack>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              onClick={handleSettingMenu}
              aria-label="Setting Menu"
              data-testid="settingsMenuBtn"
              sx={{
                minWidth: 0,
                padding: 0,
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>
        </Box>

        <SiteSettingsMenu
          key="buttonSettings"
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        />

        {/* <TokenClaimDialog
          open={currentModal === "tokenClaim"}
          onClose={(): void => setCurrentModal(null)}
        /> */}
      </Toolbar>
    </AppBar>
  )
}

// function RewardsButton({
//   setCurrentModal,
// }: {
//   setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>
// }): ReactElement | null {
//   const rewardBalances = useContext(RewardsBalancesContext)
//   const formattedTotal = formatBNToShortString(rewardBalances.total, 18)
//   return IS_SDL_LIVE ? (
//     <Button
//       variant="contained"
//       color="info"
//       data-testid="rewardButton"
//       onClick={() => setCurrentModal("tokenClaim")}
//       endIcon={<FerroLogo width={20} height={20} />}
//     >
//       {formattedTotal}
//     </Button>
//   ) : null
// }

export default TopMenu
