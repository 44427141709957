import { getChainId } from "src/utils"
import { ChainId } from "."
import lockOneWeek from "../assets/icons/lock-1-week.svg"
import lockOneMonth from "../assets/icons/lock-1-month.svg"
import lockSixMonths from "../assets/icons/lock-6-months.svg"
import lockOneYear from "../assets/icons/lock-1-year.svg"

export interface BoostPoolInfo {
  pid: number
  multiplier: number
  lockPeriod: number
  imgSrc: string
}

const mainnetBoostPools: BoostPoolInfo[] = [
  {
    pid: 0,
    multiplier: 100,
    lockPeriod: 2592000,
    imgSrc: lockOneWeek,
  },
  {
    pid: 1,
    multiplier: 300,
    lockPeriod: 15552000,
    imgSrc: lockOneMonth,
  },
  {
    pid: 2,
    multiplier: 600,
    lockPeriod: 31536000,
    imgSrc: lockSixMonths,
  },
  {
    pid: 3,
    multiplier: 2400,
    lockPeriod: 126230400,
    imgSrc: lockOneYear,
  },
]

const testnetBoostPools: BoostPoolInfo[] = [
  {
    pid: 0,
    multiplier: 100,
    lockPeriod: 300,
    imgSrc: lockOneWeek,
  },
  {
    pid: 1,
    multiplier: 600,
    lockPeriod: 3600,
    imgSrc: lockOneMonth,
  },
  {
    pid: 2,
    multiplier: 1800,
    lockPeriod: 86400,
    imgSrc: lockSixMonths,
  },
  {
    pid: 3,
    multiplier: 10800,
    lockPeriod: 86400 * 2,
    imgSrc: lockOneYear,
  },
]

const boostPools: BoostPoolInfo[] =
  getChainId() === ChainId.MAINNET ? mainnetBoostPools : testnetBoostPools

export default boostPools
