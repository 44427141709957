import React, { ReactElement, lazy, useMemo, useEffect } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { POOLS_MAP } from "../constants"
import { getChainId } from "src/utils"

import Home from "./Home"
const Pools = lazy(() => import("./Pools"))
const Swap = lazy(() => import("./Swap"))
const PoolDetail = lazy(() => import("./PoolDetail"))
const Rewards = lazy(() => import("./Rewards"))
const BoostCalculator = lazy(() => import("./BoostCalculator"))
const Stake = lazy(() => import("./Stake"))
const NotFound = lazy(() => import("./NotFound"))

export default function Router(): ReactElement {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    // Workaround: redirect hash routes
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", ""))
    }
  }, [navigate, location])

  const chainId = getChainId()

  const pools = useMemo(() => {
    return Object.values(POOLS_MAP).filter(
      ({ addresses }) => chainId && addresses[chainId],
    )
  }, [chainId])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/pools" element={<Pools />} />
      {pools.map(({ name, route }) => (
        <Route
          path={`/pools/${route}/*`}
          element={<PoolDetail poolName={name} />}
          key={`${name}-pool-detail`}
        />
      ))}
      <Route path="/pools/boost-calculator" element={<BoostCalculator />} />
      <Route path="/pools/*" element={<Pools />} />
      <Route path="/stake/*" element={<Stake />} />
      <Route path="/rewards/*" element={<Rewards />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
