import { Dialog, DialogContent, PaperProps } from "@mui/material"
import React, { ReactNode } from "react"
import DialogTitle from "./DialogTitle"

interface ModalProps {
  onClose: () => void
  open: boolean
  children: ReactNode
  title?: string
}

const paperProps: PaperProps = {
  sx: {
    bgcolor: "#292929",
    border: "1px solid transparent",
    borderRadius: "10px",
  },
}

const Modal = ({ onClose, title, open, children }: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={paperProps} scroll="body">
      <DialogTitle variant="h5" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default Modal
