import { defiWalletConnect, injectedMetaMaskProvider } from "../connectors"
import { AbstractConnector } from "@web3-react/abstract-connector"
import daiLogo from "../assets/icons/dai.svg"
// import maiLogo from "../assets/icons/mai.svg"
import metamaskIcon from "../assets/icons/metamask.svg"
import defiWalletIcon from "../assets/icons/defi-wallet.svg"
import braveWalletIcon from "../assets/icons/brave-wallet-icon.svg"
import threeFerLogo from "../assets/icons/3fer.svg"
import twoFerLogo from "../assets/icons/2fer.svg"
// import mai2ferLogo from "../assets/icons/mai2fer.svg"
import ferroLPTokenLogo from "../assets/icons/logo.svg"
import usdcLogo from "../assets/icons/usdc.svg"
import usdtLogo from "../assets/icons/usdt.svg"
import uscLogo from "../assets/icons/usc.svg"
import usc2FerLogo from "../assets/icons/2fer-usc.svg"
import xFerLogo from "../assets/icons/xFer.svg"
import ferLogo from "../assets/icons/fer.svg"
import lcroCroLogo from "../assets/icons/lcro-cro.svg"
import lcroLogo from "../assets/icons/lcro.svg"
import croLogo from "../assets/icons/cro.svg"
import latomAtomLogo from "../assets/icons/latom-atom.svg"
import latomLogo from "../assets/icons/latom.svg"
import atomLogo from "../assets/icons/atom.svg"
import { getSwapContract } from "src/utils/contractHelpers"
import { MetaSwapDeposit } from "types/ethers-contracts/MetaSwapDeposit"
import { SwapFlashLoanNoWithdrawFee } from "types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { getAddress } from "src/utils/addressHelpers"

export const NetworkContextName = "NETWORK"
export const STABLECOIN_POOL_V2_NAME = "3FER"
export const STABLECOIN_POOL_V3_NAME = "2FER"
export const USTC_METAPOOL_NAME = "USTCMetaPool"
export const USC_2FER_NAME = "2FER-USC"
// export const MAI_2FER_NAME = "MAI-2FER"
export const LCRO_WCRO_POOL_NAME = "LCRO-CRO"
export const LATOM_ATOM_POOL_NAME = "LATOM-ATOM"
export type PoolName =
  | typeof STABLECOIN_POOL_V2_NAME
  | typeof STABLECOIN_POOL_V3_NAME
  | typeof LCRO_WCRO_POOL_NAME
  | typeof LATOM_ATOM_POOL_NAME
  | typeof USC_2FER_NAME
  | typeof USTC_METAPOOL_NAME
// | typeof MAI_2FER_NAME

export enum ChainId {
  MAINNET = 25,
  TESTNET = 338, // FIXME
}
export enum PoolTypes {
  USD,
  CRO,
  ATOM,
}
export const buildAddresses = (
  addresses: Partial<Record<ChainId, string>>,
): Record<ChainId, string> => {
  return Object.keys(ChainId)
    .filter((key) => !isNaN(Number(key)))
    .reduce((acc, id) => {
      const numId = Number(id) as ChainId
      return { ...acc, [numId]: addresses?.[numId] || "" }
    }, {}) as Record<ChainId, string>
}
const buildPids = (
  pids: Partial<Record<ChainId, number>>,
): Record<ChainId, number | null> => {
  // @dev be careful to include pid 0 in this boolean logic
  return Object.keys(ChainId).reduce((acc, id) => {
    const numId = Number(id) as ChainId
    const pid = pids[numId]
    return { ...acc, [numId]: pid == null ? null : pid }
  }, {}) as Record<ChainId, number | null>
}

export class Token {
  readonly addresses: { [chainId in ChainId]: string }
  readonly decimals: number
  readonly symbol: string
  readonly name: string
  readonly icon: string
  readonly isLPToken: boolean

  constructor(
    addresses: { [chainId in ChainId]: string },
    decimals: number,
    symbol: string,
    name: string,
    icon: string,
    isLPToken = false,
  ) {
    this.addresses = addresses
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.icon = icon
    this.isLPToken = isLPToken
  }
}

export const SECONDS_PER_DAY = 60 * 60 * 24
export const BLOCK_TIME = 5700 // ms // FIXME
export const BLOCKS_PER_DAY = (1000 * SECONDS_PER_DAY) / BLOCK_TIME

export const MINICHEF_CONTRACT_ADDRESSES = buildAddresses({
  // FIXME
  [ChainId.TESTNET]: "0x1b7e6dC404cc8fbF89C325F2DFdc2b2e1CDE8d96",
  [ChainId.MAINNET]: "0xAB50FB1117778f293cc33aC044b5579fb03029D0",
})

// export const SWAP_MIGRATOR_USD_CONTRACT_ADDRESSES = buildAddresses({})

// export const GENERALIZED_SWAP_MIGRATOR_CONTRACT_ADDRESSES = buildAddresses({})

export const USTC_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x066F8cC865949E1A8940d235FB34a333359063D7",
})

export const USTC_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0xA5517E603D41Fe599BfB3fE7AD747A2d800f32bD",
})

export const USC_2FER_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x6A41732eaDBE15e9c9FcA2cfF1299c6321AA104B",
  [ChainId.TESTNET]: "0x7D6E0ebc7a05250613FBf281833981D250FF4D37",
})

export const USC_2FER_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xaB503674AAc0162250055129fe195Cb73997C034",
  [ChainId.TESTNET]: "0x20f6514afBbC0C4F02afa597A2971857f1103E26",
})

// export const MAI_2FER_SWAP_ADDRESSES = buildAddresses({
//   [ChainId.TESTNET]: "0x1AAa9A0C7ac8c71a35A142EF6454f8D8c8D6A412",
// })

// export const MAI_2FER_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
//   [ChainId.TESTNET]: "0x429f88387F4540C8570F2e483833FF33e31f8DC5",
// })

export const STABLECOIN_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xe8d13664a42B338F009812Fa5A75199A865dA5cD",
  [ChainId.TESTNET]: "0xfF3C3e4110ebE6c1Ba7dd64AB4fcbBe8A2bA2C0E",
})

export const STABLECOIN_SWAP_V3_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xa34C0fE36541fB085677c36B4ff0CCF5fa2B32d6",
  [ChainId.TESTNET]: "0xe3a7543eAf22F023B1dda0766f919e207c15010D",
})

export const LCRO_WCRO_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x1578C5CF4f8f6064deb167d1eeAD15dF43185afa",
  [ChainId.TESTNET]: "0x3a8067b89547495fB96D16D08Bcc3DE839F1fb9F",
})

export const LATOM_ATOM_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x5FA9412C2563c0B13CD9F96F0bd1A971F8eBdF96",
  [ChainId.TESTNET]: "0xD9549ecf522a805af494d56eB03938B4870fA93e",
})

export const STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x71923713685770D04d69d103008aafFeeBC31BDe",
  [ChainId.TESTNET]: "0x1372CF23a72F0d8357459f9cD492248CfdC8eB67",
})

export const STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xD05a67bB1e9684E8DDD19D0Bb6A713b4BEFC2C03",
  [ChainId.TESTNET]: "0x77E1A4b21f333A2D036961C13c331DE5Ce43a0b2",
})

export const LCRO_WCRO_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xC73B40a6a57cC8203462397F64527fD46766Dd85",
  [ChainId.TESTNET]: "0x23DdAC69D23bD95B3886AF5E0Fb2e1b1E8Dc3549",
})

export const LATOM_ATOM_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x260481aD807C95Ea30AcEaB5D01Afe0a76f41929",
  [ChainId.TESTNET]: "0x9a749Df5136ECC2d1E76b7B7956d0bCFD4243dc2",
})

// export const SDL_TOKEN_ADDRESSES = buildAddresses({})
//
export const USTC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x51D92dcf9DeCC0A4092787fC36CD63982032b785",
})

export const USC_2FER_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x74171B2facD27b9577C7d409bFC05b308255992D",
  [ChainId.TESTNET]: "0x7784FcD5F07bCD3009b554112505a7Ae0213639d",
})

// export const MAI_2FER_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
//   [ChainId.TESTNET]: "0xD4A046a98D4Ebb364971499E9eC24aBAb4d7dbdE",
// })

export const ENS_REGISTRAR_CONTRACT_ADDRESS = buildAddresses({
  [ChainId.MAINNET]: "0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5",
  [ChainId.TESTNET]: "0x16a23bFBcE9c53998c90201629E4cDB40B81B127",
})

// export const SDL_TOKEN = new Token(
//   SDL_TOKEN_ADDRESSES,
//   18,
//   "SDL",
//   "ferro-dao", // TBD
//   "Ferro DAO",
//   ferroLPTokenLogo,
//   false,
//   false,
// )

export const STABLECOIN_SWAP_V2_TOKEN = new Token(
  STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
  18,
  "3FER",
  "Ferro DAI/USDC/USDT",
  threeFerLogo,
  true,
)

export const USTC_SWAP_TOKEN = new Token(
  USTC_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "ferroUSTCLPToken",
  "Ferro USTC/3FER",
  ferroLPTokenLogo,
  true,
)

export const USC_2FER_TOKEN = new Token(
  USC_2FER_TOKEN_CONTRACT_ADDRESSES,
  18,
  "2FER-USC",
  "Ferro 2FER/USC",
  usc2FerLogo,
  true,
)

// export const MAI_2FER_TOKEN = new Token(
//   MAI_2FER_TOKEN_CONTRACT_ADDRESSES,
//   18,
//   "MAI-2FER",
//   "Ferro MAI/2FER",
//   mai2ferLogo,
//   true,
// )

export const STABLECOIN_SWAP_V3_TOKEN = new Token(
  STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES,
  18,
  "2FER",
  "Ferro USDC/USDT",
  twoFerLogo,
  true,
)

export const LCRO_WCRO_TOKEN = new Token(
  LCRO_WCRO_TOKEN_CONTRACT_ADDRESSES,
  18,
  "LCRO-CRO",
  "Ferro LCRO/CRO",
  lcroCroLogo,
  true,
)

export const LATOM_ATOM_TOKEN = new Token(
  LATOM_ATOM_TOKEN_CONTRACT_ADDRESSES,
  18,
  "LATOM-ATOM",
  "Ferro LATOM/ATOM",
  latomAtomLogo,
  true,
)

// Stablecoins

const DAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xF2001B145b43032AAF5Ee2884e456CCd805F677D",
  [ChainId.TESTNET]: "0x0125fB817Caf1E63A1dB4C365792B8F91D55A652",
})
export const DAI = new Token(DAI_CONTRACT_ADDRESSES, 18, "DAI", "Dai", daiLogo)

const USDC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59",
  [ChainId.TESTNET]: "0x321106E51b78E0E9CEBcFeC63C5250F0F3CcB82b",
})
export const USDC = new Token(
  USDC_CONTRACT_ADDRESSES,
  6,
  "USDC",
  "USDC Coin",
  usdcLogo,
)

const USDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x66e428c3f67a68878562e79A0234c1F83c208770",
  [ChainId.TESTNET]: "0x914a8825B29a04ae687625eCDa20B67abd0B58b1",
})
export const USDT = new Token(
  USDT_CONTRACT_ADDRESSES,
  6,
  "USDT",
  "Tether",
  usdtLogo,
)

const USTC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x08b4025cAae2aD31828D01c2d350c088286fcBcD",
})
export const USTC = new Token(
  USTC_CONTRACT_ADDRESSES,
  18,
  "USTC",
  "USTC",
  metamaskIcon,
)

const USC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xD42E078ceA2bE8D03cd9dFEcC1f0d28915Edea78",
  [ChainId.TESTNET]: "0x0aE14DD085827cC0B29B18dbb0EC43EEF13e20D7",
})
export const USC = new Token(USC_CONTRACT_ADDRESSES, 18, "USC", "USC", uscLogo)

// const MAI_CONTRACT_ADDRESSES = buildAddresses({
//   [ChainId.TESTNET]: "0xc6197419B622bA0E61BBD34F5fC29949Ff3F050F",
// })
// export const MAI = new Token(MAI_CONTRACT_ADDRESSES, 18, "MAI", "Mai", maiLogo)

// CRO
const WCRO_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23",
  [ChainId.TESTNET]: "0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4",
})
export const CRO = new Token(WCRO_CONTRACT_ADDRESSES, 18, "CRO", "CRO", croLogo)
export const WCRO = new Token(
  WCRO_CONTRACT_ADDRESSES,
  18,
  "WCRO",
  "WCRO",
  croLogo,
)
const LCRO_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x9fae23a2700feecd5b93e43fdbc03c76aa7c08a6",
  [ChainId.TESTNET]: "0xa5bdfa7874eb20824c4f95350c8d4c71ba054da7",
})
export const LCRO = new Token(
  LCRO_CONTRACT_ADDRESSES,
  18,
  "LCRO",
  "LCRO",
  lcroLogo,
)

const ATOM_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93",
  [ChainId.TESTNET]: "0xadd62ff655c1e428040b38da93cf281c3cef39ea",
})
export const ATOM = new Token(
  ATOM_CONTRACT_ADDRESSES,
  6,
  "ATOM",
  "ATOM",
  atomLogo,
)

const LATOM_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xAC974ee7fc5d083112c809cCb3FCe4a4F385750D",
  [ChainId.TESTNET]: "0xa90ee4bbe1d2a5b098f44d314d31d4764e8d1666",
})
export const LATOM = new Token(
  LATOM_CONTRACT_ADDRESSES,
  6,
  "LATOM",
  "LATOM",
  latomLogo,
)

export const STABLECOIN_POOL_TOKENS = [DAI, USDC, USDT]
export const STABLECOIN_POOL_V3_TOKENS = [USDC, USDT]
export const LCRO_CRO_POOL_TOKENS = [LCRO, WCRO]
export const LATOM_ATOM_POOL_TOKENS = [LATOM, ATOM]

export const USTC_POOL_TOKENS = [USTC, ...STABLECOIN_POOL_TOKENS]
export const USTC_UNDERLYING_POOL_TOKENS = [USTC, STABLECOIN_SWAP_V2_TOKEN]
export const USC_POOL_TOKENS = [USC, ...STABLECOIN_POOL_V3_TOKENS]
export const USC_UNDERLYING_POOL_TOKENS = [USC, STABLECOIN_SWAP_V3_TOKEN]
// export const MAI_POOL_TOKENS = [MAI, ...STABLECOIN_POOL_V3_TOKENS]
// export const MAI_UNDERLYING_POOL_TOKENS = [MAI, STABLECOIN_SWAP_V3_TOKEN]
export const SUPPORTED_ON_RAMP_TOKENS = [CRO, USDT, USDC, DAI, ATOM]
export const SUPPORTED_TOKENS = [...SUPPORTED_ON_RAMP_TOKENS, LATOM, LCRO]

export const MULTI_CALL_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x3bd51a07d83305CcDB66678b0aAdEf4BC61c601e",
  [ChainId.MAINNET]: "0x5e954f5972EC6BFc7dECd75779F10d848230345F",
})

// boost farm
export const FERRO_FARM_BOOST_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0xa5511E088dB6B70BfB945013090C14F5FD6b5ABa",
  [ChainId.MAINNET]: "0x1dd98049269e56809886Ba39563477908CceBF53",
})

// xFer
export const FERROBAR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0xDe76306F252fBf6e4683410891C392eb6aBfDDd3",
  [ChainId.MAINNET]: "0x6b82eAce10F782487B61C616B623A78c965Fdd88",
})

export const FERRO_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x34881f69dfE2A43E1D315455AC47B6aeAb44DBF9",
  [ChainId.MAINNET]: "0x39bC1e38c842C60775Ce37566D03B41A7A66C782",
})

export const FERROBOOST_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x3BF62b0a50Fe36A79ed0C29AB50d2dF8E2c28d44",
  [ChainId.MAINNET]: "0xCf3e157E2491F7D739f8923f6CeaA4656E64C92e",
})

export const FERROFARM_CONTRACT_ADDRESSES = MINICHEF_CONTRACT_ADDRESSES

// TODO: Update address
export const FERROFARMV2_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0xFFe0010e2472e092459d2BC91aCfc8dDE72e9e84",
})

export const CRONOS_CRUISER_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x966f20db537c5239739ce57d079e6bc766632b2e",
  [ChainId.MAINNET]: "0xd25358e2cAD3E1Fd165887569892A99fFFA674ac",
})

export const RARITY_REGISTRY_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.TESTNET]: "0x5B41fD7D77520ABbD99198A9Cfac59Aa18d3777e",
  [ChainId.MAINNET]: "0xbB2921C2d790fB4918C15811822F8593843fB5E7",
})

export const XFER = new Token(
  FERROBAR_CONTRACT_ADDRESSES,
  18,
  "xFER",
  "xFER",
  xFerLogo,
  true,
)

export const FER = new Token(
  FERRO_CONTRACT_ADDRESSES,
  18,
  "FER",
  "FER",
  ferLogo,
  true,
)

export const REWARD_TOKENS = [FER]

export type Pool = {
  name: PoolName
  lpToken: Token
  poolTokens: Token[]
  addresses: { [chainId in ChainId]: string }
  type: PoolTypes
  route: string
  metaSwapAddresses?: { [chainId in ChainId]: string }
  underlyingPoolTokens?: Token[]
  underlyingPool?: PoolName
  isOutdated?: boolean // pool can be outdated but not have a migration target
  rewardPids: { [chainId in ChainId]: number | null }
  version?: number
  rewarders?: [
    {
      address: { [chainId in ChainId]: string }
      token: Token
    },
  ]
}
export type PoolsMap = {
  [poolName: string]: Pool
}

export const POOLS_MAP: PoolsMap = {
  [STABLECOIN_POOL_V2_NAME]: {
    name: STABLECOIN_POOL_V2_NAME,
    addresses: STABLECOIN_SWAP_V2_ADDRESSES,
    lpToken: STABLECOIN_SWAP_V2_TOKEN,
    poolTokens: STABLECOIN_POOL_TOKENS,
    type: PoolTypes.USD,
    route: "3fer",
    rewardPids: buildPids({ [ChainId.TESTNET]: 1, [ChainId.MAINNET]: 1 }),
  },
  [USTC_METAPOOL_NAME]: {
    name: USTC_METAPOOL_NAME,
    addresses: USTC_META_SWAP_DEPOSIT_ADDRESSES,
    lpToken: USTC_SWAP_TOKEN,
    poolTokens: USTC_POOL_TOKENS,
    type: PoolTypes.USD,
    metaSwapAddresses: USTC_META_SWAP_ADDRESSES,
    underlyingPoolTokens: USTC_UNDERLYING_POOL_TOKENS,
    underlyingPool: STABLECOIN_POOL_V2_NAME,
    route: "ustc",
    rewardPids: buildPids({ [ChainId.TESTNET]: 2 }),
  },
  [STABLECOIN_POOL_V3_NAME]: {
    name: STABLECOIN_POOL_V3_NAME,
    addresses: STABLECOIN_SWAP_V3_ADDRESSES,
    lpToken: STABLECOIN_SWAP_V3_TOKEN,
    poolTokens: STABLECOIN_POOL_V3_TOKENS,
    type: PoolTypes.USD,
    route: "2fer",
    rewardPids: buildPids({ [ChainId.TESTNET]: 3, [ChainId.MAINNET]: 2 }),
  },
  [USC_2FER_NAME]: {
    name: USC_2FER_NAME,
    addresses: USC_2FER_META_SWAP_DEPOSIT_ADDRESSES,
    lpToken: USC_2FER_TOKEN,
    poolTokens: USC_POOL_TOKENS,
    type: PoolTypes.USD,
    metaSwapAddresses: USC_2FER_META_SWAP_ADDRESSES,
    underlyingPoolTokens: USC_UNDERLYING_POOL_TOKENS,
    underlyingPool: STABLECOIN_POOL_V3_NAME,
    route: "usc",
    rewardPids: buildPids({ [ChainId.TESTNET]: 15, [ChainId.MAINNET]: 9 }),
  },
  // [MAI_2FER_NAME]: {
  //   name: MAI_2FER_NAME,
  //   addresses: MAI_2FER_SWAP_DEPOSIT_ADDRESSES,
  //   lpToken: MAI_2FER_TOKEN,
  //   poolTokens: MAI_POOL_TOKENS,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: MAI_2FER_SWAP_ADDRESSES,
  //   underlyingPoolTokens: MAI_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: STABLECOIN_POOL_V3_NAME,
  //   route: "mai",
  //   rewardPids: buildPids({ [ChainId.TESTNET]: 4 }),
  //   version: 2,
  //   rewarders: [
  //     {
  //       address: buildAddresses({
  //         [ChainId.TESTNET]: "0x30d1f65402e79eB82bC66e2530b47E1C437f0897",
  //       }),
  //       token: MAI,
  //     },
  //   ],
  // },
  [LCRO_WCRO_POOL_NAME]: {
    name: LCRO_WCRO_POOL_NAME,
    addresses: LCRO_WCRO_SWAP_ADDRESSES,
    lpToken: LCRO_WCRO_TOKEN,
    poolTokens: LCRO_CRO_POOL_TOKENS,
    type: PoolTypes.CRO,
    route: "lcro",
    rewardPids: buildPids({ [ChainId.TESTNET]: 5, [ChainId.MAINNET]: 3 }),
  },
  [LATOM_ATOM_POOL_NAME]: {
    name: LATOM_ATOM_POOL_NAME,
    addresses: LATOM_ATOM_SWAP_ADDRESSES,
    lpToken: LATOM_ATOM_TOKEN,
    poolTokens: LATOM_ATOM_POOL_TOKENS,
    type: PoolTypes.ATOM,
    route: "latom",
    rewardPids: buildPids({ [ChainId.TESTNET]: 10, [ChainId.MAINNET]: 4 }),
  },
}

export function isMetaPool(poolName = ""): boolean {
  // TODO: Dynamically detect
  return new Set([USTC_METAPOOL_NAME, USC_2FER_NAME]).has(poolName)
}

// maps a symbol string to a token object
export type TokensMap = {
  [symbol: string]: Token
}

export const TOKENS_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    pool.poolTokens.forEach((token) => {
      newAcc[token.symbol] = token
    })
    newAcc[pool.lpToken.symbol] = pool.lpToken
    return newAcc
  },
  {
    [CRO.symbol]: CRO,
  } as TokensMap,
)

export const TOKENS_WITH_XFER_MAP: TokensMap = {
  ...TOKENS_MAP,
  [FER.symbol]: FER,
  [XFER.symbol]: XFER,
}

// export type TokenToPoolsMap = {
//   [tokenSymbol: string]: string[]
// }
// export const TOKEN_TO_POOLS_MAP = Object.keys(POOLS_MAP).reduce(
//   (acc, poolName) => {
//     const pool = POOLS_MAP[poolName as PoolName]
//     const newAcc = { ...acc }
//     pool.poolTokens.forEach((token) => {
//       newAcc[token.symbol] = (newAcc[token.symbol] || []).concat(
//         poolName as PoolName,
//       )
//     })
//     return newAcc
//   },
//   {} as TokenToPoolsMap,
// )

export const SWAP_CONTRACTS_MAP = Object.keys(POOLS_MAP)
  .filter((poolName) => getAddress(POOLS_MAP[poolName].addresses))
  .reduce((acc, poolName) => {
    const newAcc = { ...acc }
    newAcc[poolName] = getSwapContract(poolName as PoolName)
    return newAcc
  }, {} as { [poolName: string]: SwapFlashLoanNoWithdrawFee | MetaSwapDeposit })

// export type LPTokenToPoolsMap = {
//   [tokenSymbol: string]: PoolName
// }
// export const LPTOKEN_TO_POOL_MAP = Object.keys(POOLS_MAP).reduce(
//   (acc, poolName) => {
//     const pool = POOLS_MAP[poolName as PoolName]
//     const newAcc = { ...acc }
//     newAcc[pool.lpToken.symbol] = poolName as PoolName
//     return newAcc
//   },
//   {} as LPTokenToPoolsMap,
// )

export const TRANSACTION_TYPES = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  MIGRATE: "MIGRATE",
  STAKE_OR_CLAIM: "STAKE_OR_CLAIM",
}

export const POOL_FEE_PRECISION = 10

export enum SWAP_TYPES {
  DIRECT = "swapDirect", // route length 2
  WRAPPED = "wrapped", // CRO-WCRO
  // SYNTH_TO_SYNTH = "swapSynthToSynth", // route length 2
  // SYNTH_TO_TOKEN = "swapSynthToToken", // route length 3
  // TOKEN_TO_SYNTH = "swapTokenToSynth", // route length 3
  // TOKEN_TO_TOKEN = "swapTokenToToken", // route length 4
  INVALID = "invalid",
}

// export function getIsVirtualSwap(swapType: SWAP_TYPES): boolean {
//   return (
//     swapType === SWAP_TYPES.SYNTH_TO_SYNTH ||
//     swapType === SWAP_TYPES.SYNTH_TO_TOKEN ||
//     swapType === SWAP_TYPES.TOKEN_TO_SYNTH ||
//     swapType === SWAP_TYPES.TOKEN_TO_TOKEN
//   )
// }

export interface WalletInfo {
  name: string
  icon: string
  connector: AbstractConnector
}

export enum WALLET_CONNECTOR {
  METAMASK = "METAMASK",
  DEFIWALLET = "DEFIWALLET",
  BRAVEWALLET = "BRAVEWALLET",
}

export const SUPPORTED_WALLETS: Record<WALLET_CONNECTOR, WalletInfo> = {
  [WALLET_CONNECTOR.METAMASK]: {
    name: "MetaMask",
    icon: metamaskIcon,
    connector: injectedMetaMaskProvider,
  },
  [WALLET_CONNECTOR.DEFIWALLET]: {
    name: "Crypto.com Onchain",
    icon: defiWalletIcon,
    connector: defiWalletConnect,
  },
  [WALLET_CONNECTOR.BRAVEWALLET]: {
    name: "BraveWallet",
    icon: braveWalletIcon,
    connector: injectedMetaMaskProvider,
  },
}
