import { Token } from "src/constants"
import React, { ReactNode } from "react"
import { Box, CircularProgress } from "@mui/material"
import Text from "./Text"
import { useIsMobile } from "src/hooks/useIsMobile"
import { formatNumber } from "src/utils/parseStringToBigNumber"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { AddToMetamask } from "./AddToMetamask"

export type TransactionSummaryData = Array<
  | {
      label: string
      token: Pick<Token, "icon" | "symbol" | "name" | "addresses" | "decimals">
      amount: string
    }
  | { label: string; customElement: ReactNode }
>

export interface TransactionSummaryProps {
  data: TransactionSummaryData
  small?: boolean
  /**
   * Only need this when doing batch token approval. Its length must equals data.length
   */
  loadingState?: number[]
  showAddToken?: boolean
}

const TransactionSummary = ({
  data,
  small,
  loadingState,
  showAddToken,
}: TransactionSummaryProps) => {
  const isMobile = useIsMobile()

  return (
    <Box bgcolor="#00000080" p={3} borderRadius="10px">
      {data.map((x, i) =>
        "token" in x ? (
          <Text
            key={x.label + x.token.symbol}
            display="flex"
            justifyContent="space-between"
            mt={i > 0 ? 1 : 0}
            size={small ? "small" : "normal"}
          >
            <Box display="flex" alignItems="center">
              {loadingState ? (
                <Box mr={1} maxWidth={18} maxHeight={18}>
                  {loadingState[i] === 2 ? (
                    <CheckCircleOutlineIcon fontSize="small" color="primary" />
                  ) : loadingState[i] === 1 ? (
                    <CircularProgress size={18} />
                  ) : null}
                </Box>
              ) : null}
              <Box>{x.label}</Box>
              {showAddToken && <AddToMetamask token={x.token} />}
            </Box>
            <Box ml={2} display="flex" alignItems="center">
              <Box maxWidth="400px" sx={{ wordBreak: "break-all" }}>
                {isMobile ? formatNumber(x.amount, 2, 6) : x.amount}
              </Box>
              <Box ml="4px" mr={1}>
                {x.token.symbol}
              </Box>
              <img src={x.token.icon} height={16} />
            </Box>
          </Text>
        ) : (
          <Text
            key={x.label + i.toString()}
            display="flex"
            justifyContent="space-between"
            mt={i > 0 ? 1 : 0}
            size={small ? "small" : "normal"}
          >
            <Box>{x.label}</Box>
            <Box ml={2}>{x.customElement}</Box>
          </Text>
        ),
      )}
    </Box>
  )
}

export default TransactionSummary
