export const FETCH_CIRCULATING_FERRO = "fetchCirculatingFerro"
export const FETCH_FERRO_PRICE = "fetchFerroPrice"
export const FETCH_USER_DATA = "fetchUserData"
export const FETCH_POOL_DATA = "fetchPoolData"
export const FETCH_FARM_BOOST_DATA = "fetchFarmBoostData"
export const FETCH_APYS = "fetchApys"
export const FETCH_TVL_BY_POOL = "fetchTvlByPool"
export const FETCH_BAR_HISTORY = "fetchBarHistory"
export const GET_SWAP_VOLUME = "getSwapVolume"
export const GET_BLOCK_HEIGHT = "getBlockHeight"
export const GET_VENO_APR = "getVenoApr"
export const GET_VENO_STATEGY_APY = "getVenoStrategyApy"
export const GET_REWARD_BALANCES = "getRewardBalances"
export const GET_REWARD_TOKENS = "getRewardTokens"

export enum BoostFarmQuery {
  FERRO_BOOST_BALANCE = "ferroBoostBalance",
  FARM_BOOST_CALCULATE_TOTAL_MULTIPLIER = "farmBoostCalculateTotalMultiplier",
  FARM_BOOST_CALCULATE_EST_MULTIPLIER = "farmBoostCalculateEstMultiplier",
  FARM_BOOST_MAX_TOTAL_MULTIPLIER = "farmBoostMaxTotalMultiplier",
  GET_BOOSTED_FARMS = "getBoostedFarms",
  GET_BOOSTED_NFTS = "getBoostedNfts",
  HARVEST_BOOST_BY_PID = "harvestBoostByPid",
  HARVEST_ALL_BOOST = "harvestAllBoost",
  GET_ACTUAL_ADJUST_SHARE = "getActualAdjustShare",
  GET_POTENTIAL_ADJUST_SHARE = "getPotentialAdjustShare",
  FARM_BOOST_POOL_INFO = "farmBoostPoolInfo",
  FARM_BOOST_USER_INFO = "farmBoostUserInfo",
  INFO_API_META_USER_NFT_COLLECTIONS = "infoApiMetaUserNftCollections",
  INFO_API_META_USER_NFT_BY_ID = "infoApiMetaUserNftById",
  NFT_RARITY_BY_ID = "nftRarityById",
}
