import { FERROBOOST_CONTRACT_ADDRESSES } from "src/constants"
import { getAddress } from "src/utils/addressHelpers"
import {
  getFerroBarContract,
  getFerroBoostContract,
} from "src/utils/contractHelpers"
import { serializeStake } from "./helpers"
import { SerializedFerroBoostStake } from "./types"

const ferroBarContract = getFerroBarContract()
const ferroBoostContract = getFerroBoostContract()

export const fetchFerroBoostUserData = async (
  account: string,
): Promise<[string, Array<SerializedFerroBoostStake>]> => {
  try {
    const [userData, pendingFer] = await Promise.all([
      ferroBoostContract.getUserInfo(account),
      ferroBoostContract.pendingFer(account),
    ])
    const stakes = userData[2]

    return [pendingFer.toString(), stakes.map(serializeStake)]
  } catch (e) {
    console.error(e)
    return ["0", []]
  }
}

const fetchFerroBoostAllowance = async (account: string): Promise<string> => {
  try {
    const allowance = await ferroBarContract.allowance(
      account,
      getAddress(FERROBOOST_CONTRACT_ADDRESSES),
    )
    return allowance.toString()
  } catch (error) {
    return "0"
  }
}

export default fetchFerroBoostAllowance
