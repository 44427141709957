import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { SwapStatsReponse } from "../../utils/getSwapStats"
import {
  SwapPoolFarmStat,
  RewardData,
} from "../../utils/updateSwapPoolFarmStat"

interface GasPrices {
  gasStandard?: number
  gasFast?: number
  gasInstant?: number
}
export interface SwapStats {
  [swapAddress: string]: {
    oneDayVolume: string
    maxApr: string
    ferroApr: string
    apr: string
    tvl: string
    utilization: string
  }
}

export interface SwapFarmStat {
  lpTokenAddress: string
  allocPoints: number
  accFerPerShare: number
  ferPerDay: number
  rewardData: RewardData[]
}
export interface SwapFarmStats {
  [poolName: string]: SwapFarmStat
}
export interface TokenPricesUSD {
  [tokenSymbol: string]: number
}
export interface FerroToken {
  price: number
}
interface LastTransactionTimes {
  [transactionType: string]: number
}

interface ApplicationState extends GasPrices {
  tokenPricesUSD?: TokenPricesUSD
  totalAllocPoint?: number
  ferPerBlock?: number
  lastTransactionTimes: LastTransactionTimes
  swapStats?: SwapStats
  swapFarmStats?: SwapFarmStats
  ferro?: FerroToken
}

const initialState: ApplicationState = {
  lastTransactionTimes: {},
  gasStandard: 5000,
}

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateGasPrices(state, action: PayloadAction<GasPrices>): void {
      const { gasStandard, gasFast, gasInstant } = action.payload
      state.gasStandard = gasStandard
      state.gasFast = gasFast
      state.gasInstant = gasInstant
    },
    updateTokensPricesUSD(state, action: PayloadAction<TokenPricesUSD>): void {
      state.tokenPricesUSD = action.payload
    },
    updateFerroFarmInfo(
      state,
      action: PayloadAction<{ totalAllocPoint: number; ferPerBlock: number }>,
    ): void {
      state.totalAllocPoint = action.payload.totalAllocPoint
      state.ferPerBlock = action.payload.ferPerBlock
    },
    updateLastTransactionTimes(
      state,
      action: PayloadAction<LastTransactionTimes>,
    ): void {
      state.lastTransactionTimes = {
        ...state.lastTransactionTimes,
        ...action.payload,
      }
    },
    updateSwapStats(state, action: PayloadAction<SwapStatsReponse>): void {
      const formattedPayload = Object.keys(action.payload).reduce(
        (acc, key) => {
          const {
            maxApr: MAXAPR,
            ferroApr: FERAPR,
            APR,
            TVL,
            oneDayVolume: ODV,
          } = action.payload[key]
          if (isNaN(APR) || isNaN(TVL) || isNaN(ODV)) {
            return acc
          }
          const maxApr = MAXAPR.toFixed(18)
          const ferroApr = FERAPR.toFixed(18)
          const apr = APR.toFixed(18)
          const tvl = TVL.toFixed(18)
          const oneDayVolume = ODV.toFixed(18)
          const utilization = (TVL > 0 ? ODV / TVL : 0).toFixed(18)
          return {
            ...acc,
            [key]: {
              maxApr,
              ferroApr,
              apr,
              tvl,
              oneDayVolume,
              utilization,
            },
          }
        },
        {},
      )
      state.swapStats = formattedPayload
    },
    updateSwapPoolInfos(state, action: PayloadAction<SwapPoolFarmStat>): void {
      state.swapFarmStats = Object.fromEntries(
        action.payload.map((stat) => {
          const currentStat = state.swapFarmStats?.[stat.poolName]
          return [
            stat.poolName,
            {
              ...currentStat,
              lpTokenAddress: stat.lpTokenAddress,
              allocPoints: stat.allocPoints,
              accFerPerShare: stat.accFerPerShare,
              ferPerDay: stat.ferPerDay,
              rewardData: stat.rewardData,
            },
          ]
        }),
      )
    },
    updateFerroPrice(state, action: PayloadAction<FerroToken>): void {
      state.ferro = action.payload
    },
  },
})

export const {
  updateGasPrices,
  updateTokensPricesUSD,
  updateFerroFarmInfo,
  updateLastTransactionTimes,
  updateSwapStats,
  updateSwapPoolInfos,
  updateFerroPrice,
} = applicationSlice.actions

export default applicationSlice.reducer
