import { useEffect, useState } from "react"
import getBoost from "src/utils/getBoostStats"

export default function useXFerLocked(): number {
  const [xFerLocked, setXFerLocked] = useState<number>(0)

  useEffect(() => {
    const fetchBoostData = async () => {
      const data = await getBoost()
      setXFerLocked(data)
    }
    void fetchBoostData()
  }, [])

  return xFerLocked
}
