import "./Toast.scss"

import React, { ReactElement, ReactNode } from "react"
import classNames from "classnames"
import { Box, CircularProgress } from "@mui/material"
import Text from "./Text"
import { getMultichainScanLink } from "src/utils/getMultichainScanLink"
import { getChainId } from "src/utils"
import CheckIcon from "@mui/icons-material/Check"
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt"
import { useTranslation } from "react-i18next"

function getIconForType(type: "success" | "error" | "loading") {
  switch (type) {
    case "error":
      return <DoDisturbAltIcon fontSize="small" />
    case "success":
      return <CheckIcon fontSize="small" />
    case "loading":
      return <CircularProgress size={16} sx={{ color: "white" }} />
    default:
      return <CheckIcon fontSize="small" />
  }
}

export interface ToastProps {
  type: "success" | "error" | "loading"
  title?: string
  content: ReactNode
  transactionHash?: string
  transactionHashList?: string[]
}

export default function Toast({
  title,
  type,
  content,
  transactionHash,
  transactionHashList,
}: ToastProps): ReactElement {
  const { t } = useTranslation()
  const renderHashList = transactionHash
    ? [transactionHash]
    : transactionHashList

  return (
    <Box className={classNames("toast", `toast-${type}`)}>
      <Box className="icon-box">{getIconForType(type)}</Box>
      <Box className="content-box">
        <Text mb={1} minWidth={200}>
          {title ||
            t(
              `Transaction ${
                type === "error"
                  ? "failed"
                  : type === "loading"
                  ? "processing"
                  : "completed"
              }`,
            )}
        </Text>
        <Text mb={1}>{content}</Text>
        {renderHashList &&
          renderHashList.map((hash) => (
            <Box
              component="a"
              key={hash}
              href={getMultichainScanLink(getChainId(), hash, "tx")}
              target="_blank"
              rel="noreferrer"
            >
              <Text color="#009BE0" size="small">
                {t("View On Cronos Explorer")}
              </Text>
            </Box>
          ))}
      </Box>
      {/* <Box position="absolute" sx={{ top: 12, right: 12 }}>
        <CloseIcon onClick={props.closeToast} fontSize="small" />
      </Box> */}
    </Box>
  )
}
