import { useEffect } from "react"
import { AppDispatch } from "src/state"
import { updateFerroPrice } from "src/state/application"
import { useQuery } from "@tanstack/react-query"
import { FETCH_FERRO_PRICE } from "src/constants/queryKeys"

const FERRO_PRICE_API = process.env.REACT_APP_FERRO_PRICE_API!
const FERRO_SUPPLY_API = process.env.REACT_APP_FERRO_SUPPLY_API!

interface PriceResponse {
  data: {
    price: number | null
    generatedTimeMs: number
  }
}

export const fetchFerroPrice = async (): Promise<{
  price: number
  generatedTimeMs: number
}> => {
  return fetch(`${FERRO_PRICE_API}`, { cache: "no-cache" })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to call getFerroPrice from info server")
    })
    .then((body: PriceResponse) => {
      return {
        price: body.data.price != null ? body.data.price : 0,
        generatedTimeMs: body.data.generatedTimeMs,
      }
    })
    .catch((err) => {
      console.error(err)
      return { price: 0, generatedTimeMs: Date.now() }
    })
}

export const fetchCirculatingFerro = async (): Promise<number> => {
  return fetch(`${FERRO_SUPPLY_API}`, { cache: "no-cache" })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to call circulating ferro from info server")
    })
    .then((body: number) => {
      return body
    })
    .catch((err) => {
      console.error(err)
      return 0
    })
}

const useFerroPriceAndDispatch = (
  dispatch: AppDispatch,
  refetchInterval: number,
): void => {
  const { data: priceResult } = useQuery([FETCH_FERRO_PRICE], fetchFerroPrice, {
    refetchInterval,
  })
  useEffect(() => {
    if (priceResult) {
      dispatch(updateFerroPrice(priceResult))
    }
  }, [dispatch, priceResult])
}

export default useFerroPriceAndDispatch
