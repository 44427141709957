import "./Text.scss"
import React from "react"
import { Typography, TypographyProps } from "@mui/material"
import classNames from "classnames"

interface TextProps extends TypographyProps {
  color?: "dim" | string
  fontSize?: string
  bold?: boolean
  ellipsis?: boolean
  textTransform?: "uppercase" | "lowercase" | "capitalize"
  gradient?:
    | "gradient1"
    | "gradient2"
    | "gradient3"
    | "gradient-orange"
    | "gradient-blue"
  size?: "large" | "small" | "micro" | "normal"
}

const getFontSize = (size: "large" | "small" | "micro" | "normal") => {
  switch (size) {
    case "large":
      return "1.125rem"
    case "small":
      return "0.875rem"
    case "micro":
      return "0.75rem"
    case "normal":
      return "1rem"
  }
}

const getColorClassName = (color: "dim" | string) => {
  if (color === "dim") {
    return "dim"
  }
  return ""
}

const Text = ({
  color = "inherit",
  fontSize,
  bold,
  ellipsis,
  textTransform,
  sx,
  variant = "body1",
  gradient,
  className,
  size = "normal",
  fontWeight,
  ...props
}: TextProps) => {
  const computedFontSize = fontSize ?? getFontSize(size)

  return (
    <Typography
      variant={variant}
      sx={{
        ...sx,
        color,
        textTransform,
        ...(ellipsis
          ? {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
          : undefined),
        fontSize: computedFontSize,
        fontWeight: bold ? "bold" : fontWeight,
      }}
      className={classNames(
        "component-text",
        className,
        gradient,
        getColorClassName(color),
      )}
      {...props}
    />
  )
}

export default Text
