import { useEffect, useState } from "react"
import { getAllSwapsVolumes } from "src/utils/getSwapStats"

export default function useTotalTradingVolumes(): number {
  const [totalVolumes, setTotalVolumes] = useState<number>(0)

  useEffect(() => {
    const fetchVolumeData = async () => {
      const totalVolumes = await getAllSwapsVolumes()
      setTotalVolumes(totalVolumes)
    }
    void fetchVolumeData()
  }, [])

  return totalVolumes
}
