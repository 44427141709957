import { Box, CircularProgress } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import React from "react"
import { useTranslation } from "react-i18next"
import { useTransactionModal } from "src/providers/TransactionModalProvider"
import Modal from "./Modal"
import Text from "./Text"
import TransactionSummary from "./TransactionSummary"
import { getMultichainScanLink } from "src/utils/getMultichainScanLink"
import { getChainId } from "src/utils"
import Button from "./Button"
import { useIsMobile } from "src/hooks/useIsMobile"

function TransactionModal() {
  const { t } = useTranslation()
  const {
    summary,
    closeTransactionModal,
    loadingState,
    hash,
    batchState,
    showAddTokenAtSuccess,
  } = useTransactionModal()
  const isMobile = useIsMobile()

  return (
    <Modal title="" open={summary.length > 0} onClose={closeTransactionModal}>
      <Box
        textAlign="center"
        mt={2}
        mb={2}
        maxWidth={412}
        minWidth={isMobile ? "auto" : 365}
      >
        {loadingState < 2 ? (
          <CircularProgress size={30} />
        ) : (
          <CheckCircleOutlineIcon fontSize="large" color="primary" />
        )}
        <Text variant="h4" mt={1}>
          {loadingState === 0
            ? t("Waiting Confirmation")
            : loadingState === 1
            ? t("Transaction Processing")
            : t("Transaction Completed")}
        </Text>
        <Text mt={1}>
          {loadingState === 0
            ? t("Your transaction will happen after the confirmation.")
            : loadingState === 1
            ? t("Your transaction is being processed.")
            : t("Your transaction is completed.")}
        </Text>
      </Box>
      <TransactionSummary
        data={summary}
        loadingState={batchState.length > 1 ? batchState : undefined}
        showAddToken={showAddTokenAtSuccess}
      />
      {hash && (
        <Button mt={2} kind="outline" fullWidth>
          <a
            href={getMultichainScanLink(getChainId(), hash, "tx")}
            target="_blank"
            rel="noreferrer"
          >
            {t("View Transaction On Cronos Explorer")}
          </a>
        </Button>
      )}
    </Modal>
  )
}

export default TransactionModal
