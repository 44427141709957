import { Hidden, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { BigNumber } from "ethers"
import styles from "./Home.module.scss"
import { ReactComponent as VerticalLine } from "../assets/VerticalLine.svg"
import { ReactComponent as HorizontalLine } from "../assets/HorizontalLine.svg"
import { formatBNToShortString, formatNumberToCompactString } from "src/utils"
import useTotalTradingVolumes from "src/hooks/useTotalTradingVolumes"
import use24hrTradingVolumes from "src/hooks/use24hrTradingVolumes"
import useTVL from "src/hooks/useTVL"
import useFerroData from "src/hooks/useFerroData"
import useXFerLocked from "src/hooks/useXFerLocked"
import blockSecLogo from "../assets/icons/block-sec.svg"
import { ReactComponent as LinkIcon } from "../assets/icons/link.svg"
import Banner from "src/components/Banner"

const Home: React.FC = () => {
  const totalVolume = useTotalTradingVolumes()
  const pastDayVolume = use24hrTradingVolumes()
  const tvl = useTVL()
  const { circulating, marketcap } = useFerroData()
  const xFerLocked = useXFerLocked()

  return (
    <div className={styles.outContainer}>
      <Banner />
      <div className={styles.container}>
        <h1 className={styles.title}>
          Don’t lose on slippage
          <br />
          Trade on Ferro
        </h1>
        <h3 className={styles.subtitle}>
          Your trusted stable swap
          <br />
          venue on Cronos
          <br />
          <div className={styles.security}>
            TRUST PROVEN BY{" "}
            <a
              href="https://docs.ferroprotocol.com/extras/security"
              target="_blank"
              rel="noreferrer"
            >
              <img src={blockSecLogo} alt="BlockSec" width={108} height={24} />
              <LinkIcon width={20} height={20} />
            </a>
          </div>
        </h3>
        <div className={styles.introContainer}>
          <div className={styles.intro}>
            Optimized to bring you the best rates to trade your stablecoins and
            pegged assets.
            <br />
            Support us as liquidity providers or stake our token FER for solid
            yields.
          </div>
          <div className={styles.detail}>
            <Hidden lgDown>
              <Line />
            </Hidden>
            <Info description="Total/24hr Volume">
              $
              {totalVolume
                ? formatBNToShortString(BigNumber.from(totalVolume), 0)
                : "-"}
              /$
              {pastDayVolume ? formatNumberToCompactString(pastDayVolume) : "-"}
            </Info>
            <Line />
            <Info description="TVL">
              ${tvl ? formatBNToShortString(BigNumber.from(tvl), 0) : "-"}
            </Info>
            <Line />
            <Info description="Marketcap">
              {marketcap
                ? `$${formatBNToShortString(BigNumber.from(marketcap), 0)}`
                : "-"}
            </Info>
            <Line />
            <Info description="Circulating Supply">
              {circulating
                ? `${formatBNToShortString(BigNumber.from(circulating), 0)} FER`
                : "-"}
            </Info>
            <Line />
            <Info description="Locked in Vault">
              {xFerLocked
                ? `${formatBNToShortString(BigNumber.from(xFerLocked), 0)} xFER`
                : "-"}
            </Info>
          </div>
        </div>
      </div>
    </div>
  )
}

const Line: React.FC = () => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  return isSmDown ? (
    <HorizontalLine style={{ minHeight: "2px", width: "100%" }} />
  ) : (
    <VerticalLine style={{ minWidth: "2px" }} />
  )
}

type InfoProps = {
  description?: string
}
const Info: React.FC<InfoProps> = ({ children, description }) => (
  <div className={styles.info}>
    <h5>{children}</h5>
    {description && <div className={styles.description}>{description}</div>}
  </div>
)

export default Home
