import { Box } from "@mui/material"
import React from "react"
import useAddTokenToMetamask, {
  TokenSubset,
} from "src/hooks/useAddTokenToMetamask"
import metamaskIcon from "../assets/icons/metamask.svg"

interface Props {
  token: TokenSubset
}

export const AddToMetamask = ({ token }: Props) => {
  const { addToken, canAdd } = useAddTokenToMetamask(token)

  if (!canAdd) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={addToken}
      ml={0.5}
      bgcolor="#00000080"
      px={1}
      borderRadius="12px"
      sx={{ cursor: "pointer" }}
    >
      <Box mr="2px" fontSize="16px">
        +
      </Box>
      <img src={metamaskIcon} alt="MetaMask" width={18} />
    </Box>
  )
}
