import { AddressZero, Zero } from "@ethersproject/constants"
import { POOLS_MAP, PoolName, ChainId } from "../constants"
import {
  formatBNToPercentString,
  getChainId,
  getTokenSymbolForPoolType,
} from "../utils"
import { useMemo, useCallback } from "react"
import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { parseUnits } from "@ethersproject/units"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useActiveWeb3React } from "."
import { useSelector } from "react-redux"
import {
  getLpTokenContract,
  getPoolSwapContract,
} from "src/utils/contractHelpers"
import { SwapStats, TokenPricesUSD } from "src/state/application"
import { getAddress } from "src/utils/addressHelpers"
import {
  FETCH_USER_DATA,
  FETCH_POOL_DATA,
  FETCH_FARM_BOOST_DATA,
} from "src/constants/queryKeys"
import { MiniChef } from "types/ethers-contracts/MiniChef"
import { FerroFarmV2 } from "types/ethers-contracts/FerroFarmV2"
import { FerroFarmBoost } from "types/ethers-contracts/FerroFarmBoost"
import {
  useMiniChefContract,
  useFerroFarmV2Contract,
  useFerroFarmBoostContract,
} from "./useContract"
import {
  useCalculateTotalMultiplier,
  useMaxTotalMultiplier,
  useGetBoostedFarms,
} from "src/hooks/BoostFarming/useFarmBoostContract"

interface TokenShareType {
  percent: string
  symbol: string
  value: BigNumber
}

export interface PoolBaseData {
  adminFee: BigNumber
  aParameter: BigNumber
  name: string
  swapFee: BigNumber
  tokens: TokenShareType[]
  totalLocked: BigNumber
  virtualPrice: BigNumber
  isPaused: boolean
  lpToken: string
  isMigrated: boolean
}

export interface PoolStatsData {
  reserve?: BigNumber
  utilization?: BigNumber
  volume?: BigNumber
  apr?: BigNumber
  ferroApr?: BigNumber
  maxApr?: BigNumber
}

export interface PoolDataType extends PoolBaseData, PoolStatsData {
  lpTokenPriceUSD: BigNumber
}

export interface UserShareType {
  lpTokenBalance: BigNumber
  name: PoolName // TODO: does this need to be on user share?
  share: BigNumber
  stakedShare: BigNumber
  tokens: TokenShareType[]
  usdBalance: BigNumber
  totalStaked: BigNumber
  totalAmount: BigNumber
  stakedAmount: BigNumber
}

export interface FarmBoostData {
  isBoosted?: boolean
  baseAllocation?: BigNumber
  boostAllocation?: BigNumber
  baseShare?: BigNumber
  adjustShare?: BigNumber
  potentialAdjustShare?: BigNumber
  maxAdjustShare?: BigNumber
  adjustLpAmount?: BigNumber
  totalAdjustLpAmount?: BigNumber
  boostFactor: BigNumber
  potentialBoostFactor: BigNumber
  maxBoostFactor: BigNumber
  ferPerDay?: number
}

export type PoolDataHookReturnType = [
  PoolDataType,
  UserShareType | undefined,
  FarmBoostData | undefined,
]

export const emptyPoolData: PoolBaseData = {
  adminFee: Zero,
  aParameter: Zero,
  name: "",
  swapFee: Zero,
  tokens: [],
  totalLocked: Zero,
  virtualPrice: Zero,
  lpToken: "",
  isPaused: false,
  isMigrated: false,
}

// TODO: Extract to a map containing all pools and put in context to reduce calls
export default function usePoolData(
  poolName: PoolName,
): PoolDataHookReturnType {
  const { account, library } = useActiveWeb3React()
  const farmContract = useMiniChefContract()
  const farmV2Contract = useFerroFarmV2Contract()
  const farmBoostContract = useFerroFarmBoostContract()
  const pool = POOLS_MAP[poolName]
  const chainId = getChainId()
  const poolPid = pool.rewardPids[chainId]
  const rewardsContract = pool.version === 2 ? farmV2Contract : farmContract
  const { data: totalMultiplier } = useCalculateTotalMultiplier()
  const { data: maxTotalMultiplier } = useMaxTotalMultiplier()
  const { data: boostedPoolIds } = useGetBoostedFarms()

  const { data: rawPoolData } = useQuery(
    [FETCH_POOL_DATA, poolName],
    () => fetchPoolData(poolName),
    {
      refetchInterval: 15000,
    },
  )

  const { tokenPricesUSD, swapStats, swapFarmStats } = useSelector(
    (state: AppState) => state.application,
  )

  const poolSwapStats = useMemo(
    () => getPoolSwapStats(poolName, swapStats),
    [poolName, swapStats],
  )

  const lpTokenPriceUSD = useMemo(
    () => getPoolLpPrice(poolName, rawPoolData, tokenPricesUSD),
    [poolName, rawPoolData, tokenPricesUSD],
  )

  const poolData = useMemo(() => {
    return {
      ...emptyPoolData,
      name: poolName,
      ...rawPoolData,
      ...poolSwapStats,
      lpTokenPriceUSD,
    }
  }, [poolName, rawPoolData, poolSwapStats, lpTokenPriceUSD])

  const { data: userData } = useQuery(
    [FETCH_USER_DATA, poolName, account],
    () => fetchUserData(poolData, <string>account, <MiniChef>rewardsContract),
    {
      enabled: !!(rawPoolData && account && library && rewardsContract),
    },
  )

  const { data: boostData } = useQuery(
    [FETCH_FARM_BOOST_DATA, poolName],
    () =>
      fetchFarmBoostData(
        poolData,
        <string>account,
        farmBoostContract!,
        rewardsContract!,
      ),
    {
      enabled: !!(
        rawPoolData &&
        account &&
        library &&
        farmBoostContract &&
        rewardsContract
      ),
    },
  )

  const farmBoostData = useMemo(() => {
    const baseAllocNumber = swapFarmStats?.[poolName as string].allocPoints
    const ferPerDay = swapFarmStats?.[poolName as string].ferPerDay
    const baseAllocation = baseAllocNumber
      ? BigNumber.from(baseAllocNumber)
      : BigNumber.from(0)
    const baseShare = userData?.stakedShare
    const baseLpAmount = userData?.stakedAmount

    const boostFactor = calculateBoostFactor(
      baseAllocation,
      boostData?.boostAllocation,
      baseShare,
      boostData?.adjustShare,
    )

    const { share: potentialAdjustShare, boostFactor: potentialBoostFactor } =
      calculateShareFactorByMultiplier(
        totalMultiplier,
        baseLpAmount,
        boostData?.totalAdjustLpAmount,
        boostData?.adjustLpAmount,
        baseShare,
        baseAllocation,
        boostData?.boostAllocation,
      )

    const { share: maxAdjustShare, boostFactor: maxBoostFactor } =
      calculateShareFactorByMultiplier(
        maxTotalMultiplier,
        baseLpAmount,
        boostData?.totalAdjustLpAmount,
        boostData?.adjustLpAmount,
        baseShare,
        baseAllocation,
        boostData?.boostAllocation,
      )

    return {
      baseAllocation,
      baseShare,
      potentialAdjustShare,
      maxAdjustShare,
      ...boostData,
      boostFactor,
      potentialBoostFactor,
      maxBoostFactor,
      isBoosted:
        !!poolPid && boostedPoolIds && boostedPoolIds.includes(poolPid),
      ferPerDay:
        baseAllocNumber &&
        baseAllocNumber > 0 &&
        ferPerDay &&
        boostData?.boostAllocation
          ? (ferPerDay / baseAllocNumber) *
            (baseAllocNumber + boostData?.boostAllocation.toNumber())
          : ferPerDay
          ? //FIXME: adjust allocation ratio here (hardcode for wallet disconnected)
            ferPerDay / (getChainId() === ChainId.MAINNET ? 0.9 : 0.6)
          : undefined,
    }
  }, [
    swapFarmStats,
    poolName,
    userData?.stakedShare,
    userData?.stakedAmount,
    boostData,
    totalMultiplier,
    maxTotalMultiplier,
    boostedPoolIds,
    poolPid,
  ])

  return [poolData, userData, farmBoostData]
}

export function useInvalidateUserData(poolName: PoolName) {
  const queryClient = useQueryClient()
  const { account } = useActiveWeb3React()
  const onInvalidateUserData = useCallback(() => {
    void queryClient.invalidateQueries([FETCH_USER_DATA, poolName, account])
  }, [account, poolName, queryClient])
  return { onInvalidateUserData }
}

export function calculatePctOfTotalShare(
  lpTokenAmount?: BigNumber,
  totalLpTokenBalance?: BigNumber,
): BigNumber {
  // returns the % of total lpTokens
  if (!lpTokenAmount || !totalLpTokenBalance || totalLpTokenBalance?.isZero()) {
    return BigNumber.from("0")
  }
  return lpTokenAmount.mul(BigNumber.from(10).pow(18)).div(totalLpTokenBalance)
}

export function calculateBoostFactor(
  baseAllocation?: BigNumber,
  boostAllocation?: BigNumber,
  baseShares?: BigNumber,
  adjustShares?: BigNumber,
): BigNumber {
  const bigOne = BigNumber.from(10).pow(18)
  if (!(baseShares && adjustShares && baseAllocation && boostAllocation))
    return bigOne
  if (baseShares.isZero() || adjustShares.isZero() || baseAllocation.isZero())
    return bigOne

  const baseReward = baseAllocation.mul(baseShares)
  const boostReward = boostAllocation.mul(adjustShares)
  return baseReward.add(boostReward).mul(bigOne).div(baseReward)
}

export function calculateShareFactorByMultiplier(
  multiplier?: number,
  baseLpAmount?: BigNumber,
  totalAdjustLpAmount?: BigNumber,
  adjustLpAmount?: BigNumber,
  baseShares?: BigNumber,
  baseAllocation?: BigNumber,
  boostAllocation?: BigNumber,
): {
  share: BigNumber
  boostFactor: BigNumber
} {
  const newAdjustLpAmount =
    baseLpAmount?.mul(multiplier ?? 1) || BigNumber.from(0)
  const newAdjustShare = calculatePctOfTotalShare(
    newAdjustLpAmount,
    totalAdjustLpAmount
      ?.sub(adjustLpAmount || BigNumber.from(0))
      ?.add(newAdjustLpAmount),
  )
  const newBoostFactor = calculateBoostFactor(
    baseAllocation,
    boostAllocation,
    baseShares,
    newAdjustShare,
  )
  return {
    share: newAdjustShare,
    boostFactor: newBoostFactor,
  }
}

async function fetchFarmBoostData(
  poolData: PoolDataType,
  account: string,
  farmBoostContract: FerroFarmBoost,
  rewardContract: MiniChef | FerroFarmV2,
): Promise<Partial<FarmBoostData> | undefined> {
  const { name } = poolData
  const poolName = name as PoolName
  const pool = POOLS_MAP[poolName]
  const chainId = getChainId()
  const poolPid = pool.rewardPids[chainId]

  if (!poolPid) {
    return undefined
  }

  const [adjustShare, poolInfo, userInfo, totalAdjustLpAmount] =
    await Promise.all([
      farmBoostContract.getActualAdjustedShare(account || AddressZero, poolPid),
      farmBoostContract.poolInfo(poolPid),
      farmBoostContract.userInfo(poolPid, account || AddressZero),
      farmBoostContract.getTotalAdjustedLP(poolPid),
    ])
  const adjustLpAmount = userInfo[0]
  const boostPoolInfo = await rewardContract.poolInfo(poolInfo[1])
  const boostAllocation = boostPoolInfo[1]

  return {
    adjustShare,
    boostAllocation,
    adjustLpAmount,
    totalAdjustLpAmount,
  }
}

async function fetchUserData(
  poolData: PoolDataType,
  account: string,
  rewardContract: MiniChef | FerroFarmV2,
): Promise<UserShareType> {
  const {
    name,
    tokens: poolTokens,
    totalLocked: totalLpTokenBalance,
  } = poolData
  const poolName = name as PoolName
  const pool = POOLS_MAP[poolName]
  const chainId = getChainId()
  const poolLpContract = getLpTokenContract(poolName)
  const poolPid = pool.rewardPids[chainId]
  const effectivePoolTokens = pool.underlyingPoolTokens || pool.poolTokens

  const [userLpTokenBalance, totalStakedBalance] = await Promise.all([
    poolLpContract.balanceOf(account || AddressZero),
    poolLpContract.balanceOf(rewardContract.address || AddressZero),
  ])
  const amountStakedInRewards =
    poolPid !== null
      ? (await rewardContract.userInfo(poolPid, account)).amount
      : Zero

  const userShare = calculatePctOfTotalShare(
    userLpTokenBalance,
    totalLpTokenBalance,
  ).add(calculatePctOfTotalShare(amountStakedInRewards, totalLpTokenBalance))

  const stakedShare = calculatePctOfTotalShare(
    amountStakedInRewards,
    totalStakedBalance,
  )

  const tokenBalances = poolTokens.map((token) => token.value)
  const tokenBalancesSum: BigNumber = tokenBalances.reduce((sum, b) =>
    sum.add(b),
  )
  const userPoolTokenBalances = tokenBalances.map((balance) => {
    return userShare.mul(balance).div(BigNumber.from(10).pow(18))
  })

  const userPoolTokens = effectivePoolTokens.map((token, i) => ({
    symbol: token.symbol,
    percent: formatBNToPercentString(
      tokenBalances[i]
        .mul(10 ** 5)
        .div(
          totalLpTokenBalance.isZero() ? BigNumber.from("1") : tokenBalancesSum,
        ),
      5,
    ),
    value: userPoolTokenBalances[i],
  }))

  return {
    name: poolName,
    share: userShare,
    stakedShare,
    totalStaked: totalStakedBalance,
    totalAmount: userLpTokenBalance.add(amountStakedInRewards),
    stakedAmount: amountStakedInRewards,
    tokens: userPoolTokens,
    lpTokenBalance: userLpTokenBalance,
    usdBalance: Zero,
  }
}

export function getPoolSwapStats(
  poolName: PoolName,
  swapStats: SwapStats | undefined,
): PoolStatsData {
  const pool = POOLS_MAP[poolName]
  const poolAddress = getAddress(pool.addresses)
  const metaSwapAddress = pool.metaSwapAddresses
    ? getAddress(pool.metaSwapAddresses)
    : undefined
  const underlyingPool = metaSwapAddress || poolAddress
  const { oneDayVolume, tvl, apr, ferroApr, maxApr, utilization } =
    swapStats && underlyingPool in swapStats
      ? swapStats[underlyingPool]
      : {
          oneDayVolume: null,
          tvl: null,
          apr: null,
          ferroApr: null,
          maxApr: null,
          utilization: null,
        }
  return {
    volume: oneDayVolume ? parseUnits(oneDayVolume, 18) : undefined,
    utilization: utilization ? parseUnits(utilization, 18) : undefined,
    reserve: tvl ? parseUnits(tvl, 18) : undefined,
    maxApr: maxApr ? parseUnits(maxApr, 18) : undefined,
    ferroApr: ferroApr ? parseUnits(ferroApr, 18) : undefined,
    apr: apr ? parseUnits(apr, 18) : undefined,
  }
}

async function fetchPoolData(poolName: PoolName): Promise<PoolBaseData> {
  const pool = POOLS_MAP[poolName]
  const effectivePoolTokens = pool.underlyingPoolTokens || pool.poolTokens
  const poolSwapContract = getPoolSwapContract(poolName)
  const poolLpContract = getLpTokenContract(poolName)

  const [swapStorage, aParameter, isPaused, totalLpTokenBalance] =
    await Promise.all([
      poolSwapContract.swapStorage(),
      poolSwapContract.getA(),
      poolSwapContract.paused(),
      poolLpContract.totalSupply(),
    ])

  const virtualPrice = totalLpTokenBalance.isZero()
    ? BigNumber.from(10).pow(18)
    : await poolSwapContract.getVirtualPrice()

  const { adminFee, swapFee } = swapStorage

  // Pool token data
  const tokenBalances: BigNumber[] = await Promise.all(
    effectivePoolTokens.map(async (token, i) => {
      const balance = await poolSwapContract.getTokenBalance(i)
      return BigNumber.from(10)
        .pow(18 - token.decimals) // cast all to 18 decimals
        .mul(balance)
    }),
  )
  const tokenBalancesSum: BigNumber = tokenBalances.reduce((sum, b) =>
    sum.add(b),
  )

  const poolTokens = effectivePoolTokens.map((token, i) => ({
    symbol: token.symbol,
    percent: formatBNToPercentString(
      tokenBalances[i]
        .mul(10 ** 5)
        .div(
          totalLpTokenBalance.isZero() ? BigNumber.from("1") : tokenBalancesSum,
        ),
      5,
    ),
    value: tokenBalances[i],
  }))

  const poolData = {
    name: poolName,
    tokens: poolTokens,
    totalLocked: totalLpTokenBalance,
    virtualPrice: virtualPrice,
    adminFee: adminFee,
    swapFee: swapFee,
    aParameter,
    lpToken: pool.lpToken.symbol,
    isPaused,
    isMigrated: false,
  }
  return poolData
}

function getPoolLpPrice(
  poolName: PoolName,
  poolBaseData?: PoolBaseData,
  tokenPricesUSD?: TokenPricesUSD,
): BigNumber {
  if (!poolBaseData || !tokenPricesUSD) {
    return Zero
  }
  const pool = POOLS_MAP[poolName]
  const effectivePoolTokens = pool.underlyingPoolTokens || pool.poolTokens
  const isMetaSwap = pool.metaSwapAddresses != null

  const tokenBalancesUSD = effectivePoolTokens.map((token, i, arr) => {
    // TODO: review meta pool
    // use another token to estimate USD price of meta LP tokens
    const symbol =
      isMetaSwap && i === arr.length - 1
        ? getTokenSymbolForPoolType(pool.type)
        : token.symbol
    const balance = poolBaseData.tokens[i].value
    return balance
      .mul(parseUnits(String(tokenPricesUSD?.[symbol] || 0), 18))
      .div(BigNumber.from(10).pow(18))
  })
  const tokenBalancesUSDSum: BigNumber = tokenBalancesUSD.reduce((sum, b) =>
    sum.add(b),
  )
  const totalLpTokenBalance = poolBaseData.totalLocked
  const lpTokenPriceUSD = totalLpTokenBalance.isZero()
    ? Zero
    : tokenBalancesUSDSum
        .mul(BigNumber.from(10).pow(18))
        .div(totalLpTokenBalance)
  return lpTokenPriceUSD
}
