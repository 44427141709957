import { ChainId } from "../constants"

export const BASE_EXPLORER_URLS = {
  [ChainId.MAINNET]: "https://explorer.cronos.org",
  [ChainId.TESTNET]: "https://explorer.cronos.org/testnet",
}

export function getMultichainScanLink(
  chainId: ChainId,
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  switch (type) {
    case "tx": {
      return `${BASE_EXPLORER_URLS[chainId]}/tx/${data}`
    }
    case "token": {
      return `${BASE_EXPLORER_URLS[chainId]}/token/${data}`
    }
    case "block": {
      return `${BASE_EXPLORER_URLS[chainId]}/block/${data}`
    }
    default: {
      return `${BASE_EXPLORER_URLS[chainId]}/address/${data}`
    }
  }
}
