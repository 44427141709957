import { useMemo } from "react"
import { useSelector } from "react-redux"
import { POOLS_MAP, PoolName, PoolTypes, CRO, ATOM } from "../constants"
import { getPoolSwapStats } from "./usePoolData"
import { AppState } from "../state"
import { formatBNToString } from "../utils"

export default function use24hrTradingVolumes(): number {
  const { tokenPricesUSD, swapStats } = useSelector(
    (state: AppState) => state.application,
  )
  const pastDayVolumes = useMemo(() => {
    let totalVolumes = 0
    if (swapStats) {
      for (const poolName in POOLS_MAP) {
        const pool = POOLS_MAP[poolName]
        const swapData = getPoolSwapStats(<PoolName>poolName, swapStats)
        if (swapData.volume) {
          const volume = parseFloat(formatBNToString(swapData.volume, 18))
          const croUSD = tokenPricesUSD ? tokenPricesUSD[CRO.symbol] : 0
          const atomUSD = tokenPricesUSD ? tokenPricesUSD[ATOM.symbol] : 0
          let tokenUSD = 1
          if (pool.type === PoolTypes.CRO) {
            tokenUSD = croUSD
          } else if (pool.type === PoolTypes.ATOM) {
            tokenUSD = atomUSD
          }
          totalVolumes += volume * tokenUSD
        }
      }
    }
    return totalVolumes
  }, [tokenPricesUSD, swapStats])
  return pastDayVolumes
}
