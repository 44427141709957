import "./Button.scss"

import React, { ReactElement } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import classNames from "classnames"
import { Box, BoxProps } from "@mui/material"

interface Props extends BoxProps {
  disabled?: boolean
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
  kind?:
    | "primary"
    | "secondary"
    | "ternary"
    | "cancel"
    | "temporary"
    | "outline"
    | "ghost"
  isLoading?: boolean
  endIcon?: React.ReactNode
}

export default function Button(
  props: React.PropsWithChildren<Props>,
): ReactElement {
  const {
    kind = "primary",
    size = "medium",
    fullWidth,
    children,
    isLoading,
    disabled,
    endIcon,
    ...buttonProps
  } = props
  return (
    <Box
      component="button"
      className={classNames("button", kind, size, { fullWidth })}
      disabled={disabled || isLoading}
      {...buttonProps}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        {children}
        {isLoading && (
          <Box component="span" ml="12px" mt="4px">
            <CircularProgress size={16} color="inherit" />
          </Box>
        )}
        {endIcon && (
          <Box component="span" ml="12px" mt="4px">
            {endIcon}
          </Box>
        )}
      </Box>
    </Box>
  )
}
