import { useCallback, useState } from "react"

export const useBoolean = (
  initial: boolean,
): [boolean, () => void, () => void, () => void] => {
  const [bool, setBool] = useState(initial)
  const setTrue = useCallback(() => setBool(true), [])
  const setFalse = useCallback(() => setBool(false), [])
  const toggle = useCallback(() => setBool(!bool), [bool])
  return [bool, setTrue, setFalse, toggle]
}
