import { useQuery } from "@tanstack/react-query"
import { GET_BLOCK_HEIGHT } from "src/constants/queryKeys"
import { useMemo } from "react"
import { getBlockNumber } from "src/utils"

const MAINTENANCE_BLOCK_HEIGHT = Number(
  process.env.REACT_APP_MAINTENANCE_BLOCK_HEIGHT,
)

// Upgrade block height is 6542800
// We want to show the warning between min and max block height (-200 blocks and +200 blocks)
const MIN_BLOCK_HEIGHT = MAINTENANCE_BLOCK_HEIGHT - 200

const MAX_BLOCK_HEIGHT = MAINTENANCE_BLOCK_HEIGHT + 200

// Check upgrade status every 20 seconds
const INTERVAL_MS = 1000 * 20

export interface AbciInfoResponseBody {
  jsonrpc: string
  id: number
  result: {
    response: {
      data: string
      version: string
      /* eslint-disable camelcase */
      last_block_height: string
      /* eslint-disable camelcase */
      last_block_app_hash: string
    }
  }
}

function shouldShowWarning(lastBlockHeight: number): boolean {
  try {
    if (
      lastBlockHeight > MIN_BLOCK_HEIGHT &&
      lastBlockHeight < MAX_BLOCK_HEIGHT
    ) {
      return true
    }

    return false
  } catch (error) {
    // Failed to parse block height, let's be safe and show the warning
    return true
  }
}

function useShowCronosUpgradeWarning() {
  const {
    data: lastBlockHeight,
    isLoading,
    isError,
  } = useQuery([GET_BLOCK_HEIGHT], () => getBlockNumber(), {
    refetchInterval: INTERVAL_MS,
  })
  const showWarning = useMemo(() => {
    if (lastBlockHeight) {
      return shouldShowWarning(lastBlockHeight)
    }
    if (isError) {
      return true
    }
    return !isLoading
  }, [lastBlockHeight, isLoading, isError])

  return {
    showWarning,
  }
}

export default useShowCronosUpgradeWarning
