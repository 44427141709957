const TVL_API = process.env.REACT_APP_TVL_API!

interface TVLResponse {
  data: {
    tvl: number
    generatedTimeMs: number
  }
}

export default async function fetchTVL(): Promise<{
  tvl: number
  generatedTimeMs: number
}> {
  return fetch(`${TVL_API}`, { cache: "no-cache" })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to call getTVL from info server")
    })
    .then((body: TVLResponse) => {
      return body.data
    })
    .catch((err) => {
      console.error(err)
      return { tvl: 0, generatedTimeMs: Date.now() }
    })
}
