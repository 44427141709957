import React, { useMemo } from "react"
import { Helmet, HelmetData } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { POOLS_MAP } from "../constants"

const helmetData = new HelmetData({})

const defaultMeta = {
  title: "Ferro | Decentralized exchange for stablecoin swaps on Cronos",
  description:
    "A decentralized exchange on Cronos for stablecoins and pegged-value crypto assets. Swap assets with low fees and slippage. Stake and earn yield.",
  image: "https://ferroprotocol.com/ferro-twitter-card.png",
  card: "summary_large_image",
  siteName: "Ferro",
  type: "website",
  canonical: "https://ferroprotocol.com/",
}

const getPoolNameByPath = (path: string) => {
  return Object.values(POOLS_MAP).filter((pool) => {
    return pool.route === path
  })[0].name as string
}

const getActionNameByPath = (path: string) => {
  switch (path) {
    case "stake":
      return "Stake Liquidity"
    case "withdraw":
      return "Remove Liquidity"
    case "deposit":
    default:
      return "Add Liquidity"
  }
}

const SeoHeader: React.FC = () => {
  const { pathname } = useLocation()
  const meta = useMemo(() => {
    let { title, description } = defaultMeta
    if (pathname.includes("/swap")) {
      title = "Ferro | Swap stablecoins and pegged-value crypto assets"
      description =
        "Swap stablecoins and pegged-value crypto assets on Ferro with low fees and slippage. Trade top tokens like USDC, USDT, DAI, and more with optimal exchange rates."
    } else if (pathname.startsWith("/pools")) {
      title = "Ferro | Provide liquidity to earn swap fees and FER"
      description =
        "Earn fees and yield in FER by providing liquidity to Ferro Pools. Add tokens like USDC, USDT, CRO, ATOM and more to earn rewards from every swap. Stake LP tokens to earn higher yield."
      const paths = pathname.split("/")
      if (paths.length > 3) {
        const poolName = getPoolNameByPath(paths[2])
        const actionName = getActionNameByPath(paths[3])
        title = `Ferro | ${poolName} - ${actionName}`
      } else if (paths.length > 2 && paths[2] === "boost-calculator") {
        title = "Ferro | Boost Calculator"
        description =
          "Maximize staking yields using Ferro's intuitive Boost Calculator. Simulate pool boosts from xFER and NFT staking before depositing. Optimize APRs by staking xFER and NFTs on Ferro."
      }
    } else if (pathname.startsWith("/stake/bar")) {
      title = "Ferro | Stake FER"
      description =
        "Earn additional yield by staking FER, the governance token of Ferro Protocol. A portion of the protocol revenues is directed in the FER:xFER conversion. Withdraw staked FER anytime."
    } else if (pathname.startsWith("/stake/vault")) {
      title = "Ferro | xFER Vault"
      description =
        "Maximize returns on FER by staking into xFER vaults. Longer staking period gives you higher returns. Track your earnings and grow your crypto portfolio on Ferro Protocol."
    } else if (pathname.startsWith("/rewards")) {
      title = "Ferro | Rewards"
    }
    return {
      ...defaultMeta,
      title,
      description,
    }
  }, [pathname])

  return (
    <Helmet prioritizeSeoTags helmetData={helmetData}>
      <title>{meta.title}</title>
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />

      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
    </Helmet>
  )
}

export default SeoHeader
