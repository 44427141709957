import { FixedNumber } from "ethers"
import { formatUnits } from "@ethersproject/units"
import { AppDispatch } from "src/state"
import { FER } from "src/constants/index"
import { getFerroFarmContract } from "src/utils/contractHelpers"
import { updateFerroFarmInfo as updateFarmInfoAction } from "src/state/application"

const ferroFarmContract = getFerroFarmContract()

export default async function updateFerroFarmInfo(dispatch: AppDispatch) {
  const [totalAllocPoint, ferPerBlock] = await Promise.all([
    ferroFarmContract.totalAllocPoint(),
    ferroFarmContract.ferPerBlock(),
  ])
  const totalAllocPointFN = FixedNumber.from(totalAllocPoint).toString()
  dispatch(
    updateFarmInfoAction({
      totalAllocPoint: parseFloat(totalAllocPointFN.toString()),
      ferPerBlock: parseFloat(formatUnits(ferPerBlock, FER.decimals)),
    }),
  )
}
