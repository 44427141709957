import { BigNumber, FixedNumber } from "ethers"
import { FerroBoost } from "types/ethers-contracts/FerroBoost"
import {
  DeserializedBarData,
  DeserializedBoostData,
  DeserializedBoostPoolData,
  DeserializedFerroBoostStake,
  SerializedBarData,
  SerializedBoostData,
  SerializedBoostPoolData,
  SerializedFerroBoostStake,
} from "./types"

const transformStake = (
  stake: SerializedFerroBoostStake,
): DeserializedFerroBoostStake => {
  const {
    amount,
    weightedAmount,
    poolId,
    stakeTimestamp,
    unlockTimestamp,
    active,
  } = stake
  return {
    amount: BigNumber.from(amount),
    weightedAmount: BigNumber.from(weightedAmount),
    poolId,
    stakeTimestamp,
    unlockTimestamp,
    active,
  }
}

export const transformBarData = (
  barData: SerializedBarData,
): DeserializedBarData => {
  const { allowance, stakedBalance, totalSupply } = barData

  return {
    allowance: BigNumber.from(allowance),
    stakedBalance: BigNumber.from(stakedBalance),
    totalSupply: BigNumber.from(totalSupply),
  }
}

export const transformBoostData = (
  boostData: SerializedBoostData,
): DeserializedBoostData => {
  const { allowance, pendingFer, stakes } = boostData

  return {
    allowance: BigNumber.from(allowance),
    pendingFer: BigNumber.from(pendingFer),
    stakes: stakes.map(transformStake),
  }
}

export const serializeStake = (
  stake: FerroBoost.StakeStructOutput,
): SerializedFerroBoostStake => {
  const {
    amount,
    poolId,
    weightedAmount,
    stakeTimestamp,
    unlockTimestamp,
    active,
  } = stake
  return {
    amount: amount.toString(),
    poolId: poolId.toNumber(),
    weightedAmount: weightedAmount.toString(),
    stakeTimestamp: stakeTimestamp.toNumber(),
    unlockTimestamp: unlockTimestamp.toNumber(),
    active,
  }
}

export const transformBoostPoolData = (
  boostPoolData: SerializedBoostPoolData,
): DeserializedBoostPoolData => {
  const { totalSupply, poolWeight, poolInfo } = boostPoolData
  return {
    totalSupply: BigNumber.from(totalSupply),
    poolWeight: FixedNumber.from(poolWeight),
    poolInfo: poolInfo.map((pool) => ({
      poolId: pool.poolId,
      stakes: BigNumber.from(pool.stakes),
    })),
  }
}
