import { Components } from "@mui/material"

// eot- /* IE9 Compat Modes*/
// otf- /* Open Type Font */
// svg- /* Legacy iOS */
// ttf- /* Safari, Android, iOS */
// woff- /* Modern Browsers */
// woff2- /* Modern Browsers */

export function CssBaseLine(): Components {
  return {
    MuiCssBaseline: {
      styleOverrides: `
        @import url("https://p.typekit.net/p.css?s=1&k=jlr8rkw&ht=tk&f=15498.15508.15510.25750.25763.25752.25758&a=94414120&app=typekit&e=css");    

        @font-face {
          font-family:"roboto";
          font-weight: 700;
          src:url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
        }

        @font-face {
          font-family:"roboto";
          font-weight: 500;
          src:url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:500;font-stretch:normal;
        }

        @font-face {
          font-family:"roboto";
          font-weight: 400;
          src:url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
        }

        @font-face {
          font-family:"eurostile";
          font-weight: 400;
          src:url("https://use.typekit.net/af/d34cb9/00000000000000007735a6d1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/d34cb9/00000000000000007735a6d1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/d34cb9/00000000000000007735a6d1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
        }

        @font-face {
          font-family:"eurostile";
          font-weight: 500;
          src:url("https://use.typekit.net/af/50b645/00000000000000007735a6e0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/50b645/00000000000000007735a6e0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/50b645/00000000000000007735a6e0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:500;font-stretch:normal;
        }

        @font-face {
          font-family:"eurostile-extended";
          font-weight: 400;
          src:url("https://use.typekit.net/af/f8aa21/00000000000000007735a6d4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f8aa21/00000000000000007735a6d4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f8aa21/00000000000000007735a6d4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
        }

        @font-face {
          font-family:"eurostile-extended";
          font-weight: 500;
          src:url("https://use.typekit.net/af/3c33f8/00000000000000007735a6dc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/3c33f8/00000000000000007735a6dc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/3c33f8/00000000000000007735a6dc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
          font-display:swap;font-style:normal;font-weight:500;font-stretch:normal;
        }
        `,
    },
  }
}
