import React, { useCallback } from "react"
import { Menu, Stack, Box, IconButton } from "@mui/material"
import { ExpandMoreOutlined } from "@mui/icons-material"
import Button from "./Button"
import Text from "./Text"
import Modal from "src/components/Modal"
import { formatNumber } from "src/utils/parseStringToBigNumber"
import {
  FER,
  USDC,
  Token,
  SUPPORTED_ON_RAMP_TOKENS,
  ChainId,
} from "src/constants"
import { getAddress } from "src/utils/addressHelpers"
import { useSelector } from "react-redux"
import { AppState } from "src/state"
import { useBoolean } from "src/hooks/useBoolean"
import { useActiveWeb3React } from "src/hooks"
import { getChainId } from "src/utils"
import cdcPayLogo from "src/assets/icons/crypto-com-pay.webp"
import { OpenInNew } from "@mui/icons-material"

const FerroBuyAction = () => {
  const price = useSelector(
    (state: AppState) => state.application.ferro?.price ?? 0,
  )

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <img src={FER.icon} alt="$FER" width={20} height={20} />
      <Text>{formatNumber(price, 2, 4)}</Text>
      <a
        href={`https://vvs.finance/swap?disableCurrencySelect&inputCurrency=${getAddress(
          USDC.addresses,
        )}&outputCurrency=${getAddress(FER.addresses)}`}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button size="small" kind="outline">
          Buy
        </Button>
      </a>
    </Stack>
  )
}

export const TokenBuyAction: React.FC = () => {
  const [showModal, openModal, closeModal] = useBoolean(false)
  const tokenList = SUPPORTED_ON_RAMP_TOKENS.map((token: Token) => token.symbol)
  const tokenListStr = tokenList.join(", ")
  const CDC_PAY_URL = process.env.REACT_APP_CDC_PAY_BASE_URL!
  const { account } = useActiveWeb3React()
  const goToPay = useCallback(() => {
    let urlParamsObj: Record<string, string> = {
      publishableKey:
        getChainId() === ChainId.MAINNET
          ? "pk_live_bTDsn7wq5nvNCkCcPdC7eL85"
          : "pk_test_hHYyRpA7sGUTVUBrEo6TCeaE",
      cryptocurrencyList: tokenList.join(","),
      networkList: "cronos",
    }
    if (account) {
      urlParamsObj = {
        ...urlParamsObj,
        walletAddress: account,
      }
    }
    const fullLink =
      CDC_PAY_URL + "?" + new URLSearchParams(urlParamsObj).toString()
    window.open(fullLink, "_blank")
  }, [CDC_PAY_URL, account, tokenList])

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box width={108}>
        <Text size="micro" color="dim">
          Popular tokens
        </Text>
        <Text size="micro">{tokenListStr}</Text>
      </Box>
      <Button size="small" kind="outline" onClick={openModal}>
        Buy
      </Button>
      <Modal
        title={`Buy ${tokenListStr} with Fiat`}
        open={showModal}
        onClose={closeModal}
      >
        <Box my={2}>
          <img src={cdcPayLogo} alt="Crypto.com Pay" height={32} />
        </Box>
        <Text mb={3}>
          {`You will be redirected to a service provided and operated by a third
          party over which we have no control ("Third Party Service"). Any use
          of the Third Party Service will be at your own risk, and such use will
          be subject to and any information you provide will be governed by the
          terms of the Third party Service.`}
        </Text>
        <Box>
          <Button fullWidth endIcon={<OpenInNew />} onClick={goToPay}>
            Bring Me To Crypto.com Pay
          </Button>
          <Button kind="outline" onClick={closeModal} fullWidth mt={1}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </Stack>
  )
}

export const FerroBuyMenu: React.FC = () => {
  const price = useSelector(
    (state: AppState) => state.application.ferro?.price ?? 0,
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <img src={FER.icon} alt="$FER" width={20} height={20} />
        <Text>{formatNumber(price, 2, 4)}</Text>
        <IconButton aria-label="Token Menu" onClick={handleClick}>
          <ExpandMoreOutlined />
        </IconButton>
      </Stack>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        PaperProps={{ sx: { width: "232px", padding: "32px", mt: "16px" } }}
      >
        <Stack spacing={3}>
          <FerroBuyAction />
          <TokenBuyAction />
        </Stack>
      </Menu>
    </>
  )
}

export default FerroBuyAction
