import "./Banner.scss"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Box, styled, useMediaQuery, useTheme } from "@mui/material"
import stakeBgImage from "../../assets/stake-bg.webp"

import Text from "../Text"
import Button from "../Button"
const BgContainer = styled("div")(() => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  return {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundImage: `url(${stakeBgImage})`,
    height: "100%",
    width: isSmDown ? 0 : isLgUp ? "55%" : "40%",
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 150%",
  }
})

const PoolBoostBanner = () => {
  const navigate = useNavigate()

  const goToLink = useCallback(() => {
    navigate(`/pools`)
  }, [navigate])

  return (
    <Box className="banner-container">
      <Box zIndex={1}>
        <h3>Pool Boost is LIVE</h3>
        <Box mt="12px" mb="46px">
          <Text>Stake xFER and NFT to boost your pool APR!</Text>
        </Box>
        <Box display="flex" gap="24px">
          <Button
            sx={{ boxSizing: "border-box" }}
            size="small"
            onClick={goToLink}
          >
            Go to Pool Boost
          </Button>
          <a
            href="https://docs.ferroprotocol.com/user-guides/pool-boost"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              sx={{ boxSizing: "border-box" }}
              size="small"
              kind="outline"
            >
              Learn More
            </Button>
          </a>
        </Box>
      </Box>
      <BgContainer />
    </Box>
  )
}

export default PoolBoostBanner
