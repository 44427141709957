/* eslint-disable */
import {
  Box,
  // Collapse,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
  Menu,
  // MenuItem,
  Stack,
  useTheme,
  useMediaQuery,
  Drawer,
  // styled,
} from "@mui/material"
// import {
// ExpandLess,
// ExpandMore,
// LightMode,
// NightlightRound,
// } from "@mui/icons-material"
import React, { ReactElement } from "react"
import { NavLink, useLocation } from "react-router-dom"
// import { Check as CheckIcon } from "@mui/icons-material"
// import { useActiveWeb3React } from "../hooks"
// import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
// import { useThemeSettings } from "../providers/ThemeSettingsProvider"
import { useTranslation } from "react-i18next"
import FerroLogo from "./FerroLogo"
import Web3Status from "./Web3Status"
import classNames from "classnames"
import styles from "./TopMenu.module.scss"
import FerroBuyAction, { TokenBuyAction } from "./FerroBuyAction"

// const MenuItem = styled(MuiMenuItem)({
//   display: "flex",
//   justifyContent: "space-between",
// })

export type ActiveTabType = "SWAP" | "POOLS" | "STAKE" | "REWARDS"

interface SiteSettingsMenuProps {
  anchorEl: Element | null
  onClose?: () => void
  direction?: "right" | "left"
}
export default function SiteSettingsMenu({
  anchorEl,
  onClose,
  direction = "right",
}: SiteSettingsMenuProps): ReactElement {
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))

  return isMdDown ? (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <SettingMenuContent isMdDown isLgDown={isLgDown} />
    </Drawer>
  ) : (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: direction,
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: direction,
      }}
      data-testid="settingsMenuContainer"
      onClose={onClose}
      PaperProps={{ sx: { width: "232px", padding: "32px", mt: "16px" } }}
    >
      <SettingMenuContent isLgDown={isLgDown} />
    </Menu>
  )
}

interface SettingMenuContentProps {
  isMdDown?: boolean
  isLgDown?: boolean
}
const SettingMenuContent: React.FC<SettingMenuContentProps> = ({
  isMdDown = false,
  isLgDown = false,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const activeTab = pathname.split("/")[1]?.toUpperCase() as ActiveTabType
  return (
    <div className={classNames({ [styles.isMdDown]: isMdDown })}>
      {isMdDown && (
        <NavLink
          className={styles.logo}
          data-testid="homeNavLink"
          to="/"
          style={{ display: "flex", width: "128px", color: "transparent" }}
        >
          <FerroLogo />
        </NavLink>
      )}
      <Stack spacing={3}>
        {isMdDown && (
          <>
            <NavLink
              data-testid="swapNavLink"
              to="/swap"
              className={classNames(styles.link, {
                [styles.active]: activeTab === "SWAP",
              })}
            >
              {t("SWAP")}
            </NavLink>
            <NavLink
              data-testid="poolsNavLink"
              to="/pools"
              className={classNames(styles.link, {
                [styles.active]: activeTab === "POOLS",
              })}
            >
              {t("POOLS")}
            </NavLink>
            <NavLink
              data-testid="stakeNavLink"
              to="/stake"
              className={classNames(styles.link, {
                [styles.active]: activeTab === "STAKE",
              })}
            >
              {t("STAKE")}
            </NavLink>
            <NavLink
              to="/rewards"
              className={classNames(styles.link, {
                [styles.active]: activeTab === "REWARDS",
              })}
            >
              REWARDS
            </NavLink>
            <Box height="1px" width="200px" bgcolor="#505050" />
          </>
        )}
        {isLgDown && (
          <>
            <FerroBuyAction />
            <TokenBuyAction />
            <Web3Status fullWidth />
          </>
        )}
      </Stack>
    </div>
  )
}

// function AddTokenSection(): ReactElement | null {
//   const { addToken, canAdd } = useAddTokenToMetamask({
//     ...SDL_TOKEN,
//     icon: `${window.location.origin}/logo.svg`,
//   })
//   const { t } = useTranslation()

//   return canAdd ? (
//     <MenuItem onClick={() => addToken()}>
//       <span>{t("addSDL")}</span> <FerroLogo height={24} width={24} />
//     </MenuItem>
//   ) : null
// }

// function NetworkSection(): ReactElement {
//   const { t } = useTranslation()
//   const { chainId: activeChainId, library, account } = useActiveWeb3React()
//   const [isNetworkVisible, setIsNetworkVisible] = useState(false)
//   const networks = [
//     ChainId.MAINNET,
//     // ChainId.FANTOM,
//     ChainId.TESTNET,
//     // ...(IS_L2_SUPPORTED ? [ChainId.ARBITRUM, ChainId.OPTIMISM] : []),
//   ] // FIXME

//   return (
//     <div data-testid="networkMenuContainer">
//       <MenuItem
//         data-testid="networkMenuTitle"
//         onClick={() => setIsNetworkVisible((state) => !state)}
//       >
//         {t("network")} {isNetworkVisible ? <ExpandLess /> : <ExpandMore />}
//       </MenuItem>
//       <Collapse in={isNetworkVisible}>
//         {networks.map((chainId) => {
//           const params = SUPPORTED_NETWORKS[chainId]

//           return (
//             <ListItemButton
//               onClick={() => {
//                 void library?.send("wallet_addEthereumChain", [params, account])
//               }}
//               key={chainId}
//             >
//               <ListItemIcon sx={{ ml: 2 }}>
//                 {activeChainId === chainId && <CheckIcon fontSize="small" />}
//               </ListItemIcon>
//               <ListItemText primary={params?.chainName} />
//             </ListItemButton>
//           )
//         })}
//       </Collapse>
//     </div>
//   )
// }

// function LanguageSection(): ReactElement {
//   const { t, i18n } = useTranslation()

//   const [isLanguageVisible, setIsLanguageVisible] = useState(false)
//   const languageOptions = [
//     { displayText: "English", i18nKey: "en" },
//     { displayText: "简体中文", i18nKey: "zh" },
//   ]
//   const currentLanguage = i18n.language
//   return (
//     <div>
//       <MenuItem
//         data-testid="languageMenu"
//         onClick={() => setIsLanguageVisible((state) => !state)}
//       >
//         {t("language")}
//         {isLanguageVisible ? <ExpandLess /> : <ExpandMore />}
//       </MenuItem>
//       <Collapse in={isLanguageVisible} data-testid="languageMenuContainer">
//         {languageOptions.map(({ displayText, i18nKey }) => (
//           <ListItemButton
//             onClick={() => i18n.changeLanguage(i18nKey)}
//             key={displayText}
//           >
//             <ListItemIcon sx={{ ml: 2 }}>
//               {currentLanguage === i18nKey && <CheckIcon fontSize="small" />}
//             </ListItemIcon>
//             <ListItemText primary={displayText} />
//           </ListItemButton>
//         ))}
//       </Collapse>
//     </div>
//   )
// }

// function ThemeSection(): ReactElement {
//   const { t } = useTranslation()
//   const { themeMode, onChangeMode } = useThemeSettings()

//   const handleChangeMode = () => {
//     onChangeMode(themeMode === "dark" ? "light" : "dark")
//   }

//   return (
//     <MenuItem data-testid="themeMenuOption" onClick={handleChangeMode}>
//       {t("theme")} {themeMode === "dark" ? <NightlightRound /> : <LightMode />}
//     </MenuItem>
//   )
// }
