import "./CronosUpgradeWarning.scss"
import React, { ReactNode, memo } from "react"
import { styled } from "@mui/material"
import useShowCronosUpgradeWarning from "./useShowCronosUpgradeWarning"
import swapBgImage from "../../assets/swap-bg.png"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import FerroLogo from "../FerroLogo"
import Button from "../Button"

const UpgradePageContainer = styled("div")(() => {
  return {
    backgroundImage: `url(${swapBgImage})`,
    backgroundColor: "#000",
    minHeight: "100vh",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "16px 32px",
    color: "white",
    position: "relative",
  }
})

interface CronosUpgradeWarningProps {
  children: ReactNode
}

function CronosUpgradeWarning({
  children,
}: CronosUpgradeWarningProps): JSX.Element {
  const { t } = useTranslation()

  const { showWarning } = useShowCronosUpgradeWarning()

  if (!showWarning) {
    return <>{children}</>
  }

  return (
    <UpgradePageContainer>
      <div className="cronosUpgradeWarningPage">
        <div className="logoBox">
          <FerroLogo />
        </div>

        <Container>
          <div className="contentBox">
            <div className="title">{t("Maintenance")}</div>
            <div className="description">
              {t(
                "Exciting Cronos Mainnet upgrade in progress. Ferro, like all other application on Cronos, will be paused during the upgrade. All states will be preserved and resume after maintenance which should be completed within a few hours. Relax and check back later, FERies!",
              )}
            </div>
            <div className="buttonBox">
              <Button
                size="medium"
                onClick={() => {
                  window.open(
                    "https://blog.cronos.org/p/cronos-evm-pallene-upgrade-announcing",
                    "_blank",
                  )
                }}
              >
                {t("Read More")}
              </Button>
            </div>
          </div>
        </Container>
        <div className="announcementsBox">{t("© Ferro Protocol 2022")}</div>
      </div>
    </UpgradePageContainer>
  )
}

export default memo(CronosUpgradeWarning)
