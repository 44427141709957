import { useEagerConnect } from "../../hooks"

export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element
}): JSX.Element | null {
  // try to eagerly connect to an injected provider, if it exists and has granted access already
  useEagerConnect()

  return children
}
