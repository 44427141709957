import { useQuery } from "@tanstack/react-query"
import { GET_VENO_STATEGY_APY } from "src/constants/queryKeys"

interface VenoApy {
  apr: number
  apy: number
}
export const useVenoStrategyApy = () => {
  const REACT_APP_VENO_APY_STRATEGY = process.env.REACT_APP_VENO_APY_STRATEGY!
  return useQuery(
    [GET_VENO_STATEGY_APY],
    () =>
      fetch(`${REACT_APP_VENO_APY_STRATEGY}`, { cache: "no-cache" }).then(
        (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.json() as Partial<VenoApy>
          }
          throw new Error("Unable to call getVenoApr")
        },
      ),
    {
      enabled: !!REACT_APP_VENO_APY_STRATEGY,
    },
  )
}
