import { formatUnits } from "@ethersproject/units"
import { BigNumber } from "@ethersproject/bignumber"
import { AppDispatch } from "src/state"
import { BLOCKS_PER_DAY, SECONDS_PER_DAY } from "src/constants/index"
import {
  getFerroFarmContract,
  getRewarderContract,
} from "src/utils/contractHelpers"
import { POOLS_MAP, ChainId, Token } from "src/constants"
import { formatBNToNumber } from "src/utils"
import { getChainId } from "."
import { updateSwapPoolInfos as updateSwapPoolInfosAction } from "../state/application"

const ferroFarmContract = getFerroFarmContract()

export type RewardData = {
  allocPoint: number
  totalAllocPoint: number
  address: { [chainId in ChainId]: string }
  token: Token
  totalRewardPerSecond: number
  rewardPerDay: number
  rewardStartTimestamp: number
  rewardEndTimestamp: number
}

export type SwapPoolFarmStat = {
  poolName: string
  lpTokenAddress: string
  allocPoints: number
  accFerPerShare: number
  ferPerDay: number
  rewardData: RewardData[]
}[]

async function fetchReward(
  pid: number,
  rewarder: {
    address: { [chainId in ChainId]: string }
    token: Token
  },
) {
  const { address, token } = rewarder
  const rewardContract = getRewarderContract(address)
  const [
    rewardInfo,
    rewardTotalAllocPoint,
    rewardPerSecond,
    rewardStartTimestamp,
    rewardEndTimestamp,
  ] = await Promise.all([
    rewardContract.poolInfo(pid),
    rewardContract.totalAllocPoint(),
    rewardContract.rewardPerSecond(),
    rewardContract.rewardStartTimestamp(),
    rewardContract.rewardEndTimestamp(),
  ])
  const rewardAllocPoint = formatBNToNumber(rewardInfo.allocPoint)
  const totalAllocPoint = formatBNToNumber(rewardTotalAllocPoint)
  const totalRewardPerSecond = formatBNToNumber(rewardPerSecond)
  return {
    allocPoint: rewardAllocPoint,
    totalAllocPoint: formatBNToNumber(rewardTotalAllocPoint),
    address,
    token,
    totalRewardPerSecond,
    rewardPerDay: formatBNToNumber(
      rewardPerSecond
        .mul(SECONDS_PER_DAY)
        .mul(rewardAllocPoint)
        .div(totalAllocPoint)
        .div(BigNumber.from(10).pow(token.decimals)),
    ),
    rewardStartTimestamp: formatBNToNumber(rewardStartTimestamp),
    rewardEndTimestamp: formatBNToNumber(rewardEndTimestamp),
  }
}

export default async function updateSwapPoolFarmStat(
  dispatch: AppDispatch,
  totalAllocPoint: number,
  ferPerBlock: number,
) {
  const chainId = getChainId()
  const totalFerPerDay = ferPerBlock * BLOCKS_PER_DAY

  const poolInfos = await Promise.all(
    Object.entries(POOLS_MAP).map(async ([poolName, pool]) => {
      const pid = pool.rewardPids[chainId]
      if (!pid) return null
      const poolInfoRaw = await ferroFarmContract.poolInfo(pid)
      const allocPoints = poolInfoRaw.allocPoint
        ? formatBNToNumber(poolInfoRaw.allocPoint)
        : 0

      const rewardData: RewardData[] =
        pool.version === 2 && pool.rewarders
          ? await Promise.all(
              pool.rewarders.map((rewarder) => fetchReward(pid, rewarder)),
            )
          : []

      return {
        poolName,
        lpTokenAddress: poolInfoRaw.lpToken.toLowerCase(),
        allocPoints,
        accFerPerShare: parseFloat(
          formatUnits(poolInfoRaw.accFerPerShare).toString(),
        ),
        ferPerDay: (totalFerPerDay * allocPoints) / totalAllocPoint,
        rewardData,
      }
    }),
  )

  dispatch(
    updateSwapPoolInfosAction(
      poolInfos.filter((info) => info) as SwapPoolFarmStat,
    ),
  )
}
