import {
  FERROBAR_CONTRACT_ADDRESSES,
  FERROBOOST_CONTRACT_ADDRESSES,
  FERRO_FARM_BOOST_CONTRACT_ADDRESSES,
  MINICHEF_CONTRACT_ADDRESSES,
  FERROFARMV2_CONTRACT_ADDRESSES,
  CRONOS_CRUISER_CONTRACT_ADDRESSES,
  RARITY_REGISTRY_CONTRACT_ADDRESSES,
  POOLS_MAP,
  PoolName,
  TOKENS_MAP,
  Token,
  isMetaPool,
} from "../constants"
import { Contract, ContractInterface } from "@ethersproject/contracts"

import FERROBAR_CONTRACT_ABI from "../constants/abis/ferroBar.json"
import { FerroBar } from "../../types/ethers-contracts/FerroBar"
// import FERRO_CONTRACT_ABI from "../constants/abis/ferro.json"
// import { Ferro } from "types/ethers-contracts/Ferro"
import WETH_ABI from "../constants/abis/weth.json"
import { Weth } from "../../types/ethers-contracts/Weth"
import ERC20_ABI from "../constants/abis/erc20.json"
import { Erc20 } from "../../types/ethers-contracts/Erc20"
// import GENERALIZED_SWAP_MIGRATOR_CONTRACT_ABI from "../constants/abis/generalizedSwapMigrator.json"
// import { GeneralizedSwapMigrator } from "../../types/ethers-contracts/GeneralizedSwapMigrator"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import { LpTokenUnguarded } from "../../types/ethers-contracts/LpTokenUnguarded"
import META_SWAP_DEPOSIT_ABI from "../constants/abis/metaSwapDeposit.json"
import MINICHEF_CONTRACT_ABI from "../constants/abis/miniChef.json"
import FERROFARMV2_CONTRACT_ABI from "../constants/abis/ferroFarmV2.json"
import { MetaSwapDeposit } from "../../types/ethers-contracts/MetaSwapDeposit"
import { MiniChef } from "../../types/ethers-contracts/MiniChef"
import { FerroFarmV2 } from "../../types/ethers-contracts/FerroFarmV2"
import SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI from "../constants/abis/swapFlashLoanNoWithdrawFee.json"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { getContract } from "../utils"
import { useActiveWeb3React } from "./index"
import { useMemo } from "react"
import { FerroBoost } from "types/ethers-contracts/FerroBoost"
import FERROBOOST_CONTRACT_ABI from "../constants/abis/ferroBoost.json"
import { FerroFarmBoost } from "types/ethers-contracts/FerroFarmBoost"
import FERRO_FARM_BOOST_CONTRACT_ABI from "../constants/abis/ferroFarmBoost.json"
import { CronosCruiser } from "types/ethers-contracts/CronosCruiser"
import CRONOS_CRUISER_CONTRACT_ABI from "../constants/abis/cronosCruiser.json"
import { RarityRegistry } from "types/ethers-contracts/RarityRegistry"
import RARITY_REGISTRY_CONTRACT_ABI from "../constants/abis/rarityRegistry.json"

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: ContractInterface,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

// export function useGeneralizedSwapMigratorContract(): GeneralizedSwapMigrator | null {
//   const { chainId } = useActiveWeb3React()
//   const contractAddress = chainId
//     ? GENERALIZED_SWAP_MIGRATOR_CONTRACT_ADDRESSES[chainId]
//     : undefined
//   return useContract(
//     contractAddress,
//     GENERALIZED_SWAP_MIGRATOR_CONTRACT_ABI,
//   ) as GeneralizedSwapMigrator
// }

export function useFerroBarContract(): FerroBar | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? FERROBAR_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(contractAddress, FERROBAR_CONTRACT_ABI) as FerroBar
}

// export function useFerroContract(): Ferro | null {
//   const { chainId } = useActiveWeb3React()
//   const contractAddress = chainId
//     ? FERRO_CONTRACT_ADDRESSES[chainId]
//     : undefined
//   return useContract(contractAddress, FERRO_CONTRACT_ABI) as Ferro
// }

export function useFerroBoostContract(): FerroBoost | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? FERROBOOST_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(contractAddress, FERROBOOST_CONTRACT_ABI) as FerroBoost
}

export function useFerroFarmBoostContract(): FerroFarmBoost | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? FERRO_FARM_BOOST_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(
    contractAddress,
    FERRO_FARM_BOOST_CONTRACT_ABI,
  ) as FerroFarmBoost
}

export function useMiniChefContract(): MiniChef | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? MINICHEF_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(contractAddress, MINICHEF_CONTRACT_ABI) as MiniChef
}

export function useFerroFarmV2Contract(): FerroFarmV2 | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? FERROFARMV2_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(contractAddress, FERROFARMV2_CONTRACT_ABI) as FerroFarmV2
}

export function useCronosCruiserContract(): CronosCruiser | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? CRONOS_CRUISER_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(
    contractAddress,
    CRONOS_CRUISER_CONTRACT_ABI,
  ) as CronosCruiser
}

export function useRarityRegistryContract(): RarityRegistry | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? RARITY_REGISTRY_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(
    contractAddress,
    RARITY_REGISTRY_CONTRACT_ABI,
  ) as RarityRegistry
}

export function useWethContract(
  t: Token,
  withSignerIfPossible?: boolean,
): Weth | null {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = chainId ? t.addresses[chainId] : undefined
  return useContract(tokenAddress, WETH_ABI, withSignerIfPossible) as Weth
}

export function useTokenContract(
  t: Token,
  withSignerIfPossible?: boolean,
): Erc20 | null {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = chainId ? t.addresses[chainId] : undefined
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible) as Erc20
}

export function useSwapContract(
  poolName?: PoolName,
): SwapFlashLoanNoWithdrawFee | MetaSwapDeposit | null {
  const { chainId, account, library } = useActiveWeb3React()
  return useMemo(() => {
    if (!poolName || !library || !chainId) return null
    try {
      const pool = POOLS_MAP[poolName]
      const poolAddress = pool.addresses[chainId]
      if (!poolAddress) return null
      if (isMetaPool(poolName)) {
        return getContract(
          poolAddress,
          META_SWAP_DEPOSIT_ABI,
          library,
          account ?? undefined,
        ) as MetaSwapDeposit
      } else if (pool) {
        return getContract(
          poolAddress,
          SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI,
          library,
          account ?? undefined,
        ) as SwapFlashLoanNoWithdrawFee
      } else {
        return null
      }
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [chainId, library, account, poolName])
}

// export function useLPTokenContract<T extends PoolName>(
//   poolName: T,
// ): T extends typeof LpTokenUnguarded | null
export function useLPTokenContract(
  poolName: PoolName,
): LpTokenUnguarded | null {
  const { chainId, account, library } = useActiveWeb3React()
  return useMemo(() => {
    if (!poolName || !library || !chainId) return null
    try {
      const pool = POOLS_MAP[poolName]

      return getContract(
        pool.lpToken.addresses[chainId],
        LPTOKEN_UNGUARDED_ABI,
        library,
        account ?? undefined,
      ) as LpTokenUnguarded
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [chainId, library, account, poolName])
}

interface AllContractsObject {
  [x: string]: Erc20 | null
}
export function useAllContracts(): AllContractsObject | null {
  const { chainId, library, account } = useActiveWeb3React()
  return useMemo(() => {
    if (!library || !chainId) return {}
    const allTokensForChain = Object.values(TOKENS_MAP).filter(
      ({ addresses }) => addresses[chainId],
    )
    return allTokensForChain.reduce((acc, token) => {
      const tokenAddress = token.addresses[chainId]
      if (tokenAddress) {
        let contract = null
        try {
          contract = getContract(
            tokenAddress,
            ERC20_ABI,
            library,
            account || undefined,
          ) as Erc20
        } catch (e) {
          console.error(`Couldn't create contract for token ${tokenAddress}`)
        }
        acc[token.symbol] = contract
      }
      return acc
    }, {} as AllContractsObject)
  }, [chainId, library, account])
}
