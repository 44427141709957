import { useEffect, useState } from "react"
import fetchTVL from "src/utils/getTVL"

export default function useTVL(): number {
  const [tvl, setTvl] = useState<number>(0)

  useEffect(() => {
    const fetchTVLData = async () => {
      const data = await fetchTVL()
      if (data?.tvl) {
        setTvl(Math.round(data.tvl))
      }
    }
    void fetchTVLData()
  }, [])

  return tvl
}
