// import { Box, Link } from "@mui/material"
import React, { ReactNode, ReactText } from "react"
// import { ChainId } from "../constants"
// import LaunchIcon from "@mui/icons-material/Launch"
import Toast from "./Toast"
// import { getMultichainScanLink } from "../utils/getEtherscanLink"
// import i18n from "i18next"
import { toast as toastify } from "react-toastify"

// type TxType =
//   | "tokenApproval"
//   | "deposit"
//   | "swap"
//   | "withdraw"
//   | "claim"
//   | "migrate"
//   | "stake"
//   | "unstake"

// export const enqueuePromiseToast = (
//   chainId: ChainId,
//   promy: Promise<unknown>,
//   type: TxType,
//   additionalData?: { tokenName?: string; poolName?: string; txHash?: string },
// ): Promise<unknown> => {
//   const renderSuccessContent = (type: string) => {
//     switch (type) {
//       case "deposit":
//         return "Liquidity added successfully"
//       case "tokenApproval":
//         return i18n.t(`Approve ${additionalData?.tokenName || ""} successful`)
//       case "swap":
//         return "Swap successful"
//       case "withdraw":
//         return i18n.t("Liquidity removed successfully")
//       case "claim":
//         return i18n.t("Claim successful")
//       case "migrate":
//         return i18n.t("Migrate successful")
//       case "stake":
//         return i18n.t("Stake successful")
//       case "unstake":
//         return i18n.t("Unstake successful")
//       default:
//         return i18n.t("Transaction successful")
//     }
//   }

//   const renderContentBasedOnType = (type: string) => {
//     switch (type) {
//       case "deposit":
//         return i18n.t("Add Liquidity")
//       case "tokenApproval":
//         return i18n.t(`Approve ${additionalData?.tokenName || ""}`)
//       case "swap":
//         return i18n.t("Swap")
//       case "withdraw":
//         return i18n.t("Remove Liquidity")
//       case "claim":
//         return i18n.t("Claim")
//       case "migrate":
//         return i18n.t("Migrate")
//       case "stake":
//         return i18n.t("Stake")
//       case "unstake":
//         return i18n.t("Unstake")
//       default:
//         return i18n.t("Transaction")
//     }
//   }

//   const renderFailedContentBasedOnType = (type: string) => {
//     switch (type) {
//       case "deposit":
//         return "Liquidity addition failed, your tokens have been reverted"
//       case "tokenApproval":
//         return i18n.t(`Approve ${additionalData?.tokenName || ""}`)
//       case "swap":
//         return "Swap failed, your tokens have been reverted"
//       case "withdraw":
//         return "Liquidity removal failed, your tokens have been reverted"
//       case "claim":
//         return i18n.t("Claim")
//       case "migrate":
//         return i18n.t("Migrate")
//       case "stake":
//         return i18n.t("Stake failed")
//       case "unstake":
//         return i18n.t("Unstake failed")
//       default:
//         return i18n.t("Transaction failed")
//     }
//   }

//   return toastify.promise(
//     promy,
//     {
//       pending: {
//         render() {
//           return (
//             <Toast
//               title="Processing"
//               content={renderContentBasedOnType(type)}
//               type="loading"
//               transactionHash={additionalData?.txHash}
//             />
//           )
//         },
//       },
//       success: {
//         render(data: { transactionHash?: string }) {
//           return (
//             <Toast
//               title="Success"
//               content={renderSuccessContent(type)}
//               type="success"
//               transactionHash={data?.transactionHash || additionalData?.txHash}
//             />
//           )
//           // return (
//           //   <Box
//           //     sx={{
//           //       display: "flex",
//           //       justifyContent: "space-between",
//           //       alignItems: "center",
//           //     }}
//           //   >
//           //     <span>{renderSuccessContentBasedOnType(type)}</span>
//           //     <Link
//           //       href={getMultichainScanLink(
//           //         chainId,
//           //         data?.transactionHash ?? "",
//           //         "tx",
//           //       )}
//           //       target="_blank"
//           //       rel="noreferrer"
//           //       sx={{ alignItems: "center" }}
//           //     >
//           //       <LaunchIcon fontSize="inherit" />
//           //     </Link>
//           //   </Box>
//           // )
//         },
//       },
//       error: {
//         render() {
//           return (
//             <Toast
//               type="error"
//               title="Failed"
//               content={renderFailedContentBasedOnType(type)}
//               transactionHash={additionalData?.txHash}
//             />
//           )
//         },
//       },
//     },
//     {
//       icon: false,
//       hideProgressBar: true,
//       closeOnClick: false,
//     },
//   )
// }

export const enqueueToast = (
  type: "success" | "error",
  content: ReactNode,
  title?: string,
  transactionHash?: string,
  transactionHashList?: string[],
): ReactText => {
  return toastify(
    <Toast
      content={content}
      title={title}
      transactionHash={transactionHash}
      transactionHashList={transactionHashList}
      type={type}
    />,
    {
      icon: false,
      hideProgressBar: true,
      closeOnClick: false,
    },
  )
}
