import { BigNumber, FixedNumber } from "ethers"

export const BIG_ZERO = BigNumber.from(0)
// export const BIG_ONE = BigNumber.from(1)
// export const BIG_NINE = BigNumber.from(9)
export const BIG_TEN = BigNumber.from(10)

export const BIG_ZERO_FIXED = FixedNumber.from(0)
export const BIG_ONE_FIXED = FixedNumber.from(1)
// export const BIG_NINE_FIXED = FixedNumber.from(9)
// export const BIG_TEN_FIXED = FixedNumber.from(10)
