import { useActiveWeb3React } from "src/hooks"
import {
  useFerroBoostContract,
  useFerroFarmBoostContract,
} from "src/hooks/useContract"
import { useQuery } from "@tanstack/react-query"
import { BoostFarmQuery } from "src/constants/queryKeys"
import { BigNumber } from "ethers"
import type { FerroFarmBoost } from "types/ethers-contracts/FerroFarmBoost"

export const useBoostBalance = () => {
  const ferroBoostContract = useFerroBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery([BoostFarmQuery.FERRO_BOOST_BALANCE, account], async () => {
    if (!account || !ferroBoostContract) return BigNumber.from(0)
    const res = await ferroBoostContract.balanceOf(account)

    return res
  })
}

export const useCalculateTotalMultiplier = () => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery(
    [BoostFarmQuery.FARM_BOOST_CALCULATE_TOTAL_MULTIPLIER, account],
    async () => {
      if (!account || !ferroFarmBoostContract) return 0
      const res = await ferroFarmBoostContract.calculateTotalMultiplier(account)

      return res.toNumber()
    },
  )
}

export const useEstTotalMultiplier = (
  nfts: FerroFarmBoost.NFTStruct[] = [],
) => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  const { data: ferroBoostBalance } = useBoostBalance()
  return useQuery(
    [
      BoostFarmQuery.FARM_BOOST_CALCULATE_EST_MULTIPLIER,
      account,
      ferroBoostBalance,
      nfts,
    ],
    async () => {
      if (!account || !ferroFarmBoostContract || !ferroBoostBalance)
        return undefined
      const res =
        await ferroFarmBoostContract.calculatePotentialTotalMultiplier(
          ferroBoostBalance,
          nfts,
        )

      return res.toNumber()
    },
  )
}

export const useMaxTotalMultiplier = () => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  return useQuery(
    [BoostFarmQuery.FARM_BOOST_MAX_TOTAL_MULTIPLIER],
    async () => {
      if (!ferroFarmBoostContract) return 0
      const res = await ferroFarmBoostContract.MAX_TOTAL_MULTIPLIER()

      return res.toNumber()
    },
    {
      enabled: !!ferroFarmBoostContract,
    },
  )
}

export const queryGetBoostedFarms = async (
  ferroFarmBoostContract: FerroFarmBoost | null,
  account?: string | null,
) => {
  if (!account || !ferroFarmBoostContract) return []
  const res = await ferroFarmBoostContract.getBoostedFarms(account)

  return res.map((bn) => bn.toNumber())
}

export const useGetBoostedFarms = () => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery(
    [BoostFarmQuery.GET_BOOSTED_FARMS, account],
    () => queryGetBoostedFarms(ferroFarmBoostContract, account),
    {
      enabled: !!account,
    },
  )
}

export const queryGetBoostedNfts = async (
  ferroFarmBoostContract: FerroFarmBoost | null,
  account?: string | null,
) => {
  if (!account || !ferroFarmBoostContract) return []
  const res = await ferroFarmBoostContract.getBoostedNfts(account)

  return res.map((nft) => nft.tokenId.toString())
}

export const useGetBoostedNfts = () => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery(
    [BoostFarmQuery.GET_BOOSTED_NFTS, account],
    () => queryGetBoostedNfts(ferroFarmBoostContract, account),
    {
      enabled: !!account,
    },
  )
}

export const useGetBoostByPid = (farmId?: number | null) => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery(
    [BoostFarmQuery.HARVEST_BOOST_BY_PID, farmId, account],
    async () => {
      if (!farmId) return BigNumber.from(0)
      const rewards = await ferroFarmBoostContract?.callStatic.harvestBoost(
        farmId,
      )
      return rewards
    },
    {
      enabled: !!account,
      refetchInterval: 5000,
    },
  )
}

export const useGetAllBoostReward = () => {
  const ferroFarmBoostContract = useFerroFarmBoostContract()
  const { account } = useActiveWeb3React()
  return useQuery(
    [BoostFarmQuery.HARVEST_ALL_BOOST, account],
    async () => {
      const rewards = await ferroFarmBoostContract?.callStatic.harvestAllBoost()
      return rewards
    },
    {
      enabled: !!account,
      refetchInterval: 5000,
    },
  )
}
