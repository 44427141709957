import { ContractTransaction } from "ethers"
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
  // useEffect,
} from "react"
import { enqueueToast } from "src/components/Toastify"
import TransactionModal from "src/components/TransactionModal"
import TransactionSummary, {
  TransactionSummaryData,
} from "src/components/TransactionSummary"
import { delay } from "src/utils/delay"

export interface TransactionModalContext {
  summary: TransactionSummaryData
  closeTransactionModal: () => void
  loadingState: number
  addTransactions: (
    transactionQueue: TransactionQueueItem[],
    summary: TransactionSummaryData,
    showLoadingStatus?: boolean,
    showAddTokenAtSuccess?: boolean,
  ) => Promise<void>
  hash: string | null
  batchState: number[]
  showAddTokenAtSuccess: boolean
}

export type TransactionQueueItem = () => Promise<ContractTransaction>

const initialState: TransactionModalContext = {
  summary: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeTransactionModal: () => {},
  loadingState: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addTransactions: () => Promise.resolve(),
  hash: null,
  batchState: [],
  showAddTokenAtSuccess: false,
}

const ModalContext = createContext(initialState)

const TransactionModalProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [summary, setSummary] = useState<TransactionSummaryData>([])
  const [loadingState, setLoadingState] = useState(0)
  const [hash, setHash] = useState<string | null>(null)
  const [batchState, setBatchState] = useState<number[]>([])
  const [showAddTokenAtSuccess, setShowAddTokenAtSuccess] = useState(false)

  const closeTransactionModal = useCallback(() => {
    setSummary([])
    setHash(null)
    setLoadingState(0)
    setBatchState([])
  }, [])

  const addTransactions = useCallback(
    async (
      transactionQueue: TransactionQueueItem[],
      summary: TransactionSummaryData,
      showLoadingStatus = false,
      showAddTokenAtSuccess = false,
    ) => {
      try {
        setSummary(summary)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setShowAddTokenAtSuccess(showAddTokenAtSuccess)
        if (showLoadingStatus) {
          setBatchState(summary.map(() => 0))
        }
        const hashList = []
        for (let i = 0; i < transactionQueue.length; i++) {
          const transaction = transactionQueue[i]
          setLoadingState(0)
          const contractTransaction = await transaction()
          setLoadingState(1)
          if (showLoadingStatus) {
            setBatchState((state) => {
              const newState = [...state]
              newState[i] = 1
              return newState
            })
          }
          setHash(contractTransaction.hash)
          hashList.push(contractTransaction.hash)
          await contractTransaction.wait()
          setLoadingState(2)
          if (showLoadingStatus) {
            setBatchState((state) => {
              const newState = [...state]
              newState[i] = 2
              return newState
            })
          }
        }
        enqueueToast(
          "success",
          <TransactionSummary data={summary} small />,
          undefined,
          undefined,
          hashList,
        )
        await delay(3000)
      } finally {
        closeTransactionModal()
      }
    },
    [closeTransactionModal],
  )

  return (
    <ModalContext.Provider
      value={{
        summary,
        closeTransactionModal,
        loadingState,
        addTransactions,
        hash,
        batchState,
        showAddTokenAtSuccess,
      }}
    >
      <TransactionModal />
      {children}
    </ModalContext.Provider>
  )
}

const useTransactionModal = () => useContext(ModalContext)

export { useTransactionModal, TransactionModalProvider }
