import { JsonRpcProvider } from "@ethersproject/providers"
import { useCallback, useEffect, useState } from "react"
import { ChainId, ENS_REGISTRAR_CONTRACT_ADDRESS } from "src/constants"
import { isAddress } from "src/utils"
import { useActiveWeb3React } from "."

function getENSProvider(
  ensAddress?: string,
  chainId?: ChainId,
): JsonRpcProvider {
  return new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL, {
    chainId: chainId ?? 338,
    name: chainId === 25 ? "cronos-mainnet" : "cronos-testnet",
    ensAddress,
  })
}

export const useENSName = (address?: string | null) => {
  const { chainId } = useActiveWeb3React()
  const [ensName, setEnsName] = useState<string | null>()

  const resolveName = useCallback(async () => {
    if (!address || !isAddress(address)) return undefined
    const contractAddress = chainId
      ? ENS_REGISTRAR_CONTRACT_ADDRESS[chainId]
      : undefined
    return getENSProvider(contractAddress, chainId).lookupAddress(address)
  }, [address, chainId])

  useEffect(() => {
    const execResolveName = async () => {
      try {
        const name = await resolveName()
        setEnsName(name)
      } catch (e) {
        console.error(e)
      }
    }
    if (address) execResolveName()
    else setEnsName(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return { ensName }
}
