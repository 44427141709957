import { IconButton, Typography, TypographyProps } from "@mui/material"
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle"
import React, { PropsWithChildren, ReactElement } from "react"
import { Close } from "@mui/icons-material"
import { useIsMobile } from "src/hooks/useIsMobile"

interface Props {
  onClose?: () => void
}

export default function DialogTitle({
  children,
  onClose,
  variant = "h3",
  ...props
}: PropsWithChildren<unknown> &
  Props &
  DialogTitleProps &
  TypographyProps): ReactElement {
  const isMobile = useIsMobile()

  return (
    <MuiDialogTitle>
      <Typography component="span" variant={variant} {...props}>
        {children}
      </Typography>
      {onClose && (
        <IconButton
          sx={{
            position: "absolute",
            right: isMobile ? 14 : 12,
            top: isMobile ? 16 : 10,
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}
