import "./Footer.scss"
import React, { ReactElement } from "react"
import { Box, Hidden, Stack } from "@mui/material"
import { useLocation } from "react-router-dom"
import { useIsLaptop } from "src/hooks/useIsMobile"
import { ReactComponent as DiscordIcon } from "../assets/icons/DiscordIcon.svg"
import { ReactComponent as MediumIcon } from "../assets/icons/MediumIcon.svg"
import { ReactComponent as TwitterIcon } from "../assets/icons/TwitterIcon.svg"
import { ReactComponent as PowerByCronosIcon } from "../assets/icons/power-by-cronos.svg"
import { ReactComponent as HorizontalLine } from "../assets/HorizontalLine.svg"
import Text from "./Text"

export default function Footer(): ReactElement {
  const isLaptop = useIsLaptop()
  const location = useLocation()
  const isHidden = location.pathname === "/pools/boost-calculator"

  return (
    <Box
      display={isHidden ? "none" : "flex"}
      px={isLaptop ? 2 : 4}
      py={3}
      width="100vw"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={isLaptop ? "column" : "row"}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={{ xs: 3, lg: 5, xl: 7 }}
        flexDirection={isLaptop ? "column" : "row"}
      >
        <Stack direction="row" spacing={3}>
          <a
            href="https://twitter.com/FerroProtocol"
            target="_blank"
            rel="noreferrer"
            aria-label="Ferro Twitter"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://discord.com/invite/RkA2RCtTD3"
            target="_blank"
            rel="noreferrer"
            aria-label="Ferro Discord"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://medium.com/@FerroProtocol"
            target="_blank"
            rel="noreferrer"
            aria-label="Ferro Medium"
          >
            <MediumIcon />
          </a>
        </Stack>
        <a
          className="doc-link"
          href="https://docs.ferroprotocol.com/"
          target="_blank"
          rel="noreferrer"
          aria-label="Ferro Documents"
        >
          READ DOCS
        </a>
      </Box>
      <Hidden mdUp>
        <HorizontalLine
          style={{ margin: "24px 0", minHeight: "2px", width: "100%" }}
        />
      </Hidden>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={isLaptop ? "column" : "row"}
        gap={isLaptop ? 1 : 3}
      >
        <PowerByCronosIcon />
        <Text size="micro">
          Powered by Cronos Labs. All Rights Reserved Ferro.
        </Text>
      </Box>
    </Box>
  )
}
