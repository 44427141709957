import { FixedNumber } from "ethers"
import { ChainId } from "src/constants"
import boostPools from "src/constants/boostPools"
import { getChainId } from "src/utils"
import {
  getFerroBoostContract,
  getFerroFarmContract,
} from "src/utils/contractHelpers"

const ferroBoostContract = getFerroBoostContract()
const ferroFarmContract = getFerroFarmContract()

const pid = getChainId() === ChainId.MAINNET ? 0 : 0

type PoolInfo = Array<{ poolId: number; stakes: string }>

export const fetchFerroBoostPoolData = async (
  existingTotalAllocPoint?: number,
): Promise<[string, string, ...PoolInfo]> => {
  const [totalSupply, totalAllocPoint, info, ...boostPoolinfo] =
    await Promise.all([
      ferroBoostContract.totalSupply(),
      existingTotalAllocPoint ?? ferroFarmContract.totalAllocPoint(),
      ferroFarmContract.poolInfo(pid),
      ...boostPools.map((pool) => ferroBoostContract.poolInfo(pool.pid)),
    ])
  const formattedPoolInfo = boostPoolinfo.map((pool, i) => ({
    poolId: i,
    stakes: pool.totalStaked.toString(),
  }))

  const allocPointFN = FixedNumber.from(info.allocPoint)
  const totalAllocPointFN = FixedNumber.from(totalAllocPoint)
  const poolWeight = allocPointFN.divUnsafe(totalAllocPointFN)

  return [totalSupply.toString(), poolWeight.toString(), ...formattedPoolInfo]
}
