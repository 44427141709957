import { FERROBAR_CONTRACT_ADDRESSES, XFER } from "src/constants"
import { getAddress } from "src/utils/addressHelpers"
import {
  getFerroBarContract,
  getFerroContract,
} from "src/utils/contractHelpers"

const ferroContract = getFerroContract()
const ferroBarContract = getFerroBarContract()

export const fetchFerroBarBalance = async (): Promise<string> => {
  const balance = await ferroContract.balanceOf(
    getAddress(FERROBAR_CONTRACT_ADDRESSES),
  )
  return balance.toString()
}

export const fetchFerroBarTotalSupply = async (): Promise<string> => {
  const totalSupply = await ferroBarContract.totalSupply()
  return totalSupply.toString()
}

const fetchFerroBarAllowance = async (account: string): Promise<string> => {
  try {
    const allowance = await ferroContract.allowance(
      account,
      getAddress(XFER.addresses),
    )
    return allowance.toString()
  } catch (error) {
    return "0"
  }
}

export default fetchFerroBarAllowance
