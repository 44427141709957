import "../styles/global.scss"
import "react-toastify/dist/ReactToastify.css"

import { AppDispatch, AppState } from "../state"
import React, { ReactElement, Suspense, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { styled, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import TopMenu from "../components/TopMenu"
import Web3ReactManager from "../components/Web3ReactManager"
import bgImage from "../assets/landing-page-bg.webp"
import useSwapStats from "../utils/getSwapStats"
import useTokenPricesUSD from "../utils/useTokenPricesUSD"
import { updateTokensPricesUSD } from "../state/application"
import { IS_DEVELOPMENT } from "../utils/environment"
import useFerroPriceAndDispatch from "../utils/getFerroData"
import updateFerroFarmInfo from "../utils/updateFarmInfo"
import updateSwapPoolFarmStat from "../utils/updateSwapPoolFarmStat"
import swapBgImage from "../assets/swap-bg.webp"
import poolBgImage from "../assets/pool-bg.webp"
import stakeBgImage from "../assets/stake-bg.webp"
import { useThemeSettings } from "src/providers/ThemeSettingsProvider"
import { TransactionModalProvider } from "src/providers/TransactionModalProvider"
import { getChainId } from "src/utils"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import CronosUpgradeWarning from "../components/CronosUpgradeWarning/CronosUpgradeWarning"
import ConditionalWrapper from "../components/ConditionalWrapper"
import SeoHeader from "../components/SeoHeader"
import Footer from "../components/Footer"
import Loading from "./Loading"
import Router from "./Router"

const MAINTENANCE_PAGE_SUPPORTED =
  process.env.REACT_APP_MAINTENANCE_PAGE_SUPPORTED === "true"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failCount) => failCount < 5,
      refetchOnWindowFocus: false,
    },
  },
})

const AppContainer = styled("div")(() => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const location = useLocation()
  let img = ""
  let rwdStyle = {}
  if (location.pathname.includes("/swap")) {
    img = swapBgImage
    rwdStyle = {
      backgroundSize: "cover",
    }
  } else if (location.pathname.startsWith("/pools")) {
    img = poolBgImage
    rwdStyle = {
      backgroundSize: "cover",
    }
  } else if (location.pathname.startsWith("/stake")) {
    img = stakeBgImage
    rwdStyle = {
      backgroundSize: "cover",
    }
  } else if (
    location.pathname === "/" ||
    location.pathname.startsWith("/rewards")
  ) {
    img = bgImage
    rwdStyle = {
      backgroundPosition: "center 10vh",
    }

    if (isSmDown) {
      rwdStyle = {
        backgroundPosition: "center 40vh",
        backgroundSize: "160%",
      }
    }
  }
  return {
    backgroundImage: img ? `url(${img})` : "none",
    backgroundColor: "#000",
    minHeight: "100vh",
    backgroundAttachment: isMdDown ? "inherit" : "fixed",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    ...rwdStyle,
  }
})
export default function App(): ReactElement {
  const { themeMode } = useThemeSettings()

  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <ConditionalWrapper
          condition={MAINTENANCE_PAGE_SUPPORTED}
          wrapper={(children) => (
            <CronosUpgradeWarning>{children}</CronosUpgradeWarning>
          )}
        >
          <Web3ReactManager>
            <GasAndTokenPrices>
              <TransactionModalProvider>
                <SeoHeader />
                <AppContainer>
                  <TopMenu />
                  <Router />
                  <Footer />
                  <ToastContainer
                    theme={themeMode !== "system" ? themeMode : "dark"}
                    position="top-right"
                    className="ferro-toast-container"
                    toastClassName="ferro-toast"
                  />
                  {IS_DEVELOPMENT && <ReactQueryDevtools />}
                </AppContainer>
              </TransactionModalProvider>
            </GasAndTokenPrices>
          </Web3ReactManager>
        </ConditionalWrapper>
      </QueryClientProvider>
    </Suspense>
  )
}

function GasAndTokenPrices({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const dispatchRef = useRef(dispatch)
  dispatchRef.current = dispatch

  // const { chainId } = useActiveWeb3React()
  const chainId = getChainId()
  const aggregatedPrice = useTokenPricesUSD()

  useEffect(() => {
    void updateFerroFarmInfo(dispatchRef.current)
  }, [])

  const totalAllocPoint = useSelector<AppState, number | undefined>(
    (s) => s.application.totalAllocPoint,
  )
  const ferPerBlock = useSelector<AppState, number | undefined>(
    (s) => s.application.ferPerBlock,
  )
  useEffect(() => {
    if (totalAllocPoint && ferPerBlock) {
      void updateSwapPoolFarmStat(
        dispatchRef.current,
        totalAllocPoint,
        ferPerBlock,
      )
    }
  }, [totalAllocPoint, ferPerBlock])

  useEffect(() => {
    dispatchRef.current(updateTokensPricesUSD(aggregatedPrice))
  }, [aggregatedPrice, chainId])

  useFerroPriceAndDispatch(dispatchRef.current, 15000)
  useSwapStats(dispatchRef.current, 15000)

  return <>{children}</>
}
