import { FixedNumber } from "ethers"
import { useQuery } from "@tanstack/react-query"
import { fetchFerroPrice, fetchCirculatingFerro } from "src/utils/getFerroData"
import { sanitizeStringForFN } from "src/utils/parseStringToBigNumber"
import {
  FETCH_FERRO_PRICE,
  FETCH_CIRCULATING_FERRO,
} from "src/constants/queryKeys"

export default function useFerroData(): {
  price: number
  circulating: number
  marketcap: number
} {
  const { data: priceResult } = useQuery([FETCH_FERRO_PRICE], fetchFerroPrice)
  const { data: supplyResult } = useQuery(
    [FETCH_CIRCULATING_FERRO],
    fetchCirculatingFerro,
  )

  const price = priceResult
    ? FixedNumber.from(sanitizeStringForFN(priceResult.price.toString()))
    : FixedNumber.from("0")
  const circulating = supplyResult
    ? FixedNumber.from(sanitizeStringForFN(supplyResult.toString()))
    : FixedNumber.from("0")
  const marketcap =
    priceResult && supplyResult ? price.mulUnsafe(circulating).toString() : "0"

  return {
    price: parseFloat(price.toString()),
    circulating: parseInt(circulating.toString()),
    marketcap: parseInt(marketcap),
  }
}
