import { useMemo, useCallback, useEffect } from "react"
import { gql, request } from "graphql-request"
import { find } from "lodash"
import { getUnixTime, sub } from "date-fns"
import { POOLS_MAP, isMetaPool } from "src/constants"
import {
  FETCH_APYS,
  FETCH_TVL_BY_POOL,
  GET_SWAP_VOLUME,
} from "src/constants/queryKeys"
import { useQuery, useQueries } from "@tanstack/react-query"
import { getChainId } from "src/utils"
import { AppDispatch } from "../state"
import { updateSwapStats } from "../state/application"
import { getAddress } from "./addressHelpers"

const SWAP_CLIENT = process.env.REACT_APP_SWAP_URL!
const APYS_API = process.env.REACT_APP_APYS_API!
const TVL_API = process.env.REACT_APP_TVL_API!

const getDayAgoTimestamp = (): number => {
  const dayAgo = sub(new Date(), { days: 1 })
  return getUnixTime(dayAgo)
}

interface TotalVolumeResponse {
  weeklyVolumes: { volume: string }[]
}

const fetchAllSwapsVolumes = async (): Promise<{
  data: TotalVolumeResponse
  error?: boolean
}> => {
  try {
    const GET_SWAP_POOLS_VOLUMES = gql`
      query swapPoolsVolume {
        weeklyVolumes {
          volume
        }
      }
    `

    const historiesDatas = await request<TotalVolumeResponse>(
      SWAP_CLIENT,
      GET_SWAP_POOLS_VOLUMES,
    )
    const data = historiesDatas
    return { data, error: false }
  } catch (error) {
    console.error(
      "Failed to fetch total volume of all swaps from subgraph",
      error,
    )
    return { data: { weeklyVolumes: [] }, error: true }
  }
}

export const getAllSwapsVolumes = async (): Promise<number> => {
  const { data } = await fetchAllSwapsVolumes()
  return data.weeklyVolumes.reduce(
    (totalVolume: number, volumes: Record<string, string>) => {
      totalVolume = totalVolume + parseInt(volumes.volume)
      return totalVolume
    },
    0,
  )
}

// interface TotalVolume24hrResponse {
//   hourlyVolumes: { volume: string }[]
// }

// const fetchAllSwaps24hrVolumes = async (
//   timestamp: number,
// ): Promise<{
//   data: TotalVolume24hrResponse
//   error?: boolean
// }> => {
//   try {
//     const GET_SWAP_POOLS_24HR_VOLUMES = gql`
//       query swapPoolsVolume($timestampStart: BigInt!) {
//         hourlyVolumes(where: { timestamp_gt: $timestampStart }) {
//           volume
//         }
//       }
//     `

//     const historiesDatas = await request<TotalVolume24hrResponse>(
//       SWAP_CLIENT,
//       GET_SWAP_POOLS_24HR_VOLUMES,
//       {
//         timestampStart: timestamp,
//       },
//     )
//     const data = historiesDatas
//     return { data, error: false }
//   } catch (error) {
//     console.error(
//       "Failed to fetch 24hr total volume of all swaps from subgraph",
//       error,
//     )
//     return { data: { hourlyVolumes: [] }, error: true }
//   }
// }

// export const getAllSwaps24hrVolumes = async (): Promise<number> => {
//   const { data } = await fetchAllSwaps24hrVolumes(getDayAgoTimestamp())
//   return data.hourlyVolumes.reduce(
//     (totalVolume: number, volumes: Record<string, string>) => {
//       totalVolume = totalVolume + parseFloat(volumes.volume)
//       return totalVolume
//     },
//     0,
//   )
// }

interface SwapOneDayVolumeResponse {
  swaps: SwapOneDayVolume[]
}

interface SwapOneDayVolume {
  address: string
  hourlyVolumes: Record<string, string>[]
}

const fetchSwap = async (
  timestamp: number,
): Promise<{ data: SwapOneDayVolumeResponse; error?: unknown }> => {
  // get 24 hr volume from subgraph
  try {
    const GET_SWAP_POOLS_24HR_VOLUMES = gql`
      query swapPoolsHourlyVolume($timestampStart: BigInt!) {
        swaps {
          address
          hourlyVolumes(where: { timestamp_gt: $timestampStart }) {
            volume
            timestamp
          }
        }
      }
    `

    const historiesDatas = await request<SwapOneDayVolumeResponse>(
      SWAP_CLIENT,
      GET_SWAP_POOLS_24HR_VOLUMES,
      {
        timestampStart: timestamp,
      },
    )
    const data = historiesDatas
    return { data, error: false }
  } catch (error) {
    console.error("Failed to fetch swap one day volume data", error)
    return { data: { swaps: [] }, error }
  }
}

const getSwapVolume = async () => {
  const { data, error } = await fetchSwap(getDayAgoTimestamp())
  if (error) {
    throw error
  }
  const volumes: Record<string, number> = data.swaps.reduce(
    (volumeMap: Record<string, number>, swapPool) => {
      // volume7d
      const volume7d: number = swapPool.hourlyVolumes.reduce(
        (totalVolume: number, curr: Record<string, string>) => {
          totalVolume = totalVolume + parseFloat(curr.volume)
          return totalVolume
        },
        0,
      )

      return {
        ...volumeMap,
        [swapPool.address]: volume7d,
      }
    },
    {},
  )
  return volumes
}

interface ApysResponse {
  data: Record<string, ApysDetailResponse>
}
interface ApysDetailResponse {
  lpToken: string
  baseApy: number
  baseApr: number
  ferroApr: number
  ferroApy: number
  otherApr: number
  otherApy: number
  maxApr: number
}

const fetchApys = () =>
  fetch(`${APYS_API}`, { cache: "no-cache" })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to call getApys from info server")
    })
    .then((body: ApysResponse) => {
      return body.data
    })
interface TvlResponse {
  data: TvlData
}

interface TvlData {
  tvl: number
}

const fetchTvlByPool = (poolName: string) =>
  fetch(`${TVL_API}/${poolName}`, { cache: "no-cache" })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to call getTvl from info server")
    })
    .then((body: TvlResponse) => {
      return body.data
    })

export interface SwapStatsReponse {
  [swapAddress: string]: {
    oneDayVolume: number
    maxApr: number
    ferroApr: number
    APR: number
    TVL: number
  }
}

export default function useSwapStats(
  dispatch: AppDispatch,
  refetchInterval: number,
) {
  const chainId = getChainId()
  const pools = useMemo(() => {
    return Object.values(POOLS_MAP).filter(
      ({ addresses }) => chainId && addresses[chainId],
    )
  }, [chainId])
  const { data: oneDayVolumeData } = useQuery(
    [GET_SWAP_VOLUME],
    getSwapVolume,
    {
      refetchInterval,
    },
  )
  const { data: apys } = useQuery([FETCH_APYS], fetchApys, {
    refetchInterval,
  })
  const poolTvlResults = useQueries({
    queries: pools.map((pool) => {
      return {
        queryKey: [FETCH_TVL_BY_POOL, pool.name],
        queryFn: () => fetchTvlByPool(pool.name),
        refetchInterval,
      }
    }),
  })
  const res = useMemo<SwapStatsReponse>(
    () =>
      pools.reduce((acc, pool, index) => {
        const address: string =
          isMetaPool(pool.name) && pool.metaSwapAddresses
            ? getAddress(pool.metaSwapAddresses)
            : getAddress(pool.addresses)
        const lpTokenAddress = getAddress(pool.lpToken.addresses)
        const tvl: TvlData | undefined = poolTvlResults[index]?.data
        const apr: ApysDetailResponse | undefined =
          apys &&
          find(Object.values(apys), {
            lpToken: lpTokenAddress.toLowerCase(),
          })
        const baseApr = apr?.baseApr ?? 0
        const ferroApr = apr?.ferroApr ?? 0
        const otherApr = apr?.otherApr ?? 0
        const maxApr = apr?.maxApr ?? 0
        return {
          ...acc,
          [address]: {
            oneDayVolume: oneDayVolumeData
              ? oneDayVolumeData[address.toLowerCase()]
              : 0,
            TVL: tvl ? tvl.tvl : 0,
            maxApr,
            ferroApr,
            APR: baseApr + ferroApr + otherApr,
          },
        }
      }, {}),
    [pools, poolTvlResults, apys, oneDayVolumeData],
  )

  const dispatchUpdate = useCallback(
    (swapStats: SwapStatsReponse) => {
      dispatch(updateSwapStats(swapStats))
    },
    [dispatch],
  )
  useEffect(() => {
    if (res) {
      dispatchUpdate(res)
    }
  }, [res, dispatchUpdate])
}
