import { BigNumber } from "@ethersproject/bignumber"
import { TOKENS_MAP } from "../constants"
import { parseUnits } from "@ethersproject/units"
import { FixedNumber } from "ethers"
import { BIG_TEN } from "src/constants/bigNumber"
import { commify } from "."

/**
 * Parses a user input string into a BigNumber.
 * Uses the native precision of the token if a tokenSymbol is provided
 * Defaults to a value of 0 if string cannot be parsed
 *
 * @param {string} valueRaw
 * @param {number} precision
 * @param {BigNumber} fallback
 * @return {Object} result
 * @return {BigNumber} result.value
 * @return {boolean} result.isFallback
 * }
 */
export default function parseStringToBigNumber(
  valueRaw: string,
  precision: number,
  fallback?: BigNumber,
): { value: BigNumber; isFallback: boolean } {
  let valueSafe: BigNumber
  let isFallback: boolean
  try {
    // attempt to parse string. Use fallback value if library error is thrown
    valueSafe = parseUnits(valueRaw, precision)
    isFallback = false
  } catch {
    valueSafe = fallback ?? BigNumber.from("0")
    isFallback = true
  }
  return { value: valueSafe, isFallback }
}

/**
 * Parses a user input string into a BigNumber.
 * Uses the native precision of the token if a tokenSymbol is provided
 * Defaults to a value of 0 if string cannot be parsed
 * @param {string} value
 * @param {string} tokenSymbol
 * @return {Object} result
 * @return {BigNumber} result.value
 * @return {boolean} result.isFallback */
export function parseStringAndTokenToBigNumber(
  value: string,
  tokenSymbol?: string,
): { value: BigNumber; isFallback: boolean } {
  return parseStringToBigNumber(
    value,
    tokenSymbol ? TOKENS_MAP[tokenSymbol]?.decimals : 18,
    BigNumber.from("0"),
  )
}

export function formatNumber(
  number: number | BigNumber | string | FixedNumber,
  minPrecision = 2,
  maxPrecision = 6,
): string {
  try {
    const stringifiedNumber = number.toString()
    if (stringifiedNumber === "") {
      return ""
    }
    const sanitizedNumber = sanitizeStringForFN(stringifiedNumber)
    const value = FixedNumber.from(sanitizedNumber)
    const rounded = value.round(maxPrecision).toString()
    const [whole, fraction] = rounded.split(".")
    let padded: string
    if (fraction.length > minPrecision) {
      padded = rounded
    } else if (fraction.length < minPrecision) {
      const newFraction = fraction.padEnd(minPrecision, "0")
      padded = whole + "." + newFraction
    } else {
      padded = rounded
    }
    return commify(padded)
  } catch (e) {
    console.error(e)
    return ""
  }
}

export function getRawAmount(
  number: BigNumber | FixedNumber,
  decimals = 18,
): FixedNumber {
  const factor = BIG_TEN.pow(decimals)

  return FixedNumber.from(number.toString()).divUnsafe(FixedNumber.from(factor))
}

export function sanitizeStringForFN(value: string): string {
  if (!value || value === ".") {
    return "0.0"
  }
  const [whole, fraction] = value.split(".")
  if (fraction !== undefined) {
    if (fraction.includes("e+")) {
      return value
    }
    if (fraction.length > 18) {
      return whole + "." + fraction.substring(0, 18)
    }
  }
  return value
}
