import { FixedNumber } from "ethers"
import { AddressZero, Zero } from "@ethersproject/constants"
import {
  ChainId,
  MULTI_CALL_CONTRACT_ADDRESSES,
  PoolTypes,
  TOKENS_MAP,
  Token,
} from "../constants"
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers"
import { formatUnits, parseUnits } from "@ethersproject/units"

import { BigNumber } from "@ethersproject/bignumber"
import { Contract } from "@ethersproject/contracts"
import { ContractInterface } from "ethers"
import { Deadlines } from "../state/user"
import { MulticallProvider } from "../types/ethcall"
import { Provider } from "ethcall"
import { getAddress } from "@ethersproject/address"
import { getAddress as getAddressUtil } from "src/utils/addressHelpers"
import parseStringToBigNumber from "./parseStringToBigNumber"
import { getNetworkLibrary } from "src/connectors"

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string,
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: ContractInterface,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export function formatBNToNumber(bn: BigNumber): number {
  return parseFloat(FixedNumber.from(bn).toString())
}

const BN_SHORT_SUFFIXES_UPPER_CASE = ["", "K", "M", "B", "T"]
const BN_SHORT_SUFFIXES_LOWER_CASE = ["", "k", "m", "b", "t"]
export function formatBNToShortString(
  bn: BigNumber,
  nativePrecision: number,
  suffixesUpperCase?: boolean,
): string {
  const bnStr = bn.toString()
  const numLen = bnStr.length - nativePrecision
  if (numLen <= 0) return "0"
  const div = Math.floor((numLen - 1) / 3)
  const mod = numLen % 3
  const decimalDigit = numLen <= 3 ? "0" : bnStr[mod || 3]
  const suffixes = suffixesUpperCase
    ? BN_SHORT_SUFFIXES_UPPER_CASE
    : BN_SHORT_SUFFIXES_LOWER_CASE
  return `${bnStr.substr(0, mod || 3)}${"." + decimalDigit}${suffixes[div]}`
}

export function formatBNToString(
  bn: BigNumber,
  nativePrecison: number,
  decimalPlaces?: number,
): string {
  const fullPrecision = formatUnits(bn, nativePrecison)
  const decimalIdx = fullPrecision.indexOf(".")
  return decimalPlaces === undefined || decimalIdx === -1
    ? fullPrecision
    : fullPrecision.slice(
        0,
        decimalIdx + (decimalPlaces > 0 ? decimalPlaces + 1 : 0), // don't include decimal point if places = 0
      )
}

export function formatBNToPercentString(
  bn: BigNumber,
  nativePrecison: number,
  decimalPlaces = 2,
): string {
  return `${formatBNToString(bn, nativePrecison - 2, decimalPlaces)}%`
}

export function formatNumberToString(
  n: number,
  opts?: ConstructorParameters<typeof Intl.NumberFormat>[1],
) {
  return new Intl.NumberFormat("en-US", { useGrouping: true, ...opts }).format(
    n,
  )
}

export function formatNumberToCompactString(
  n: number,
  opts?: ConstructorParameters<typeof Intl.NumberFormat>[1],
) {
  return formatNumberToString(n, {
    notation: "compact",
    maximumFractionDigits: 2,
    ...opts,
  })
}

export function shiftBNDecimals(bn: BigNumber, shiftAmount: number): BigNumber {
  if (shiftAmount < 0) throw new Error("shiftAmount must be positive")
  return bn.mul(BigNumber.from(10).pow(shiftAmount))
}

export function calculateExchangeRate(
  amountFrom: BigNumber,
  tokenPrecisionFrom: number,
  amountTo: BigNumber,
  tokenPrecisionTo: number,
): BigNumber {
  return amountFrom.gt("0")
    ? amountTo
        .mul(BigNumber.from(10).pow(36 - tokenPrecisionTo)) // convert to standard 1e18 precision
        .div(amountFrom.mul(BigNumber.from(10).pow(18 - tokenPrecisionFrom)))
    : BigNumber.from("0")
}

export function formatDeadlineToNumber(
  deadlineSelected: Deadlines,
  deadlineCustom?: string,
): number {
  let deadline = 20
  switch (deadlineSelected) {
    case Deadlines.Ten:
      deadline = 10
      break
    case Deadlines.Twenty:
      deadline = 20
      break
    case Deadlines.Thirty:
      deadline = 30
      break
    case Deadlines.Forty:
      deadline = 40
      break
    case Deadlines.Custom:
      deadline = +(deadlineCustom || formatDeadlineToNumber(Deadlines.Twenty))
      break
  }
  return deadline
}

// A better version of ether's commify util
export function commify(str: string): string {
  const parts = str.split(".")
  if (parts.length > 2) throw new Error("commify string cannot have > 1 period")
  const [partA, partB] = parts
  if (partA.length === 0) return partB === undefined ? "" : `.${partB}`
  const mod = partA.length % 3
  const div = Math.floor(partA.length / 3)
  // define a fixed length array given the expected # of commas added
  const commaAArr = new Array(partA.length + (mod === 0 ? div - 1 : div))
  // init pointers for original string and for commified array
  let commaAIdx = commaAArr.length - 1
  // iterate original string backwards from the decimals since that's how commas are added
  for (let i = partA.length - 1; i >= 0; i--) {
    // revIdx is the distance from the decimal place eg "3210."
    const revIdx = partA.length - 1 - i
    // add the character to the array
    commaAArr[commaAIdx--] = partA[i]
    // add a comma if we are a multiple of 3 from the decimal
    if ((revIdx + 1) % 3 === 0) {
      commaAArr[commaAIdx--] = ","
    }
  }
  const commifiedA = commaAArr.join("")
  return partB === undefined ? commifiedA : `${commifiedA}.${partB}`
}

export function intersection<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  return new Set([...set1].filter((item) => set2.has(item)))
}

export function getTokenByAddress(
  address: string,
  chainId: ChainId,
): Token | null {
  return (
    Object.values(TOKENS_MAP).find(
      ({ addresses }) =>
        addresses[chainId] &&
        address.toLowerCase() === addresses[chainId].toLowerCase(),
    ) || null
  )
}

export function calculatePrice(
  amount: BigNumber | string,
  tokenPrice = 0,
  decimals?: number,
): BigNumber {
  // returns amount * price as BN 18 precision
  if (typeof amount === "string") {
    if (isNaN(+amount)) return Zero
    const amountBN = parseStringToBigNumber(amount, 18).value
    const priceBN = parseStringToBigNumber(tokenPrice.toFixed(6), 18).value
    return amountBN.mul(priceBN).div(BigNumber.from(10).pow(18))

    return parseUnits((+amount * tokenPrice).toFixed(2), 18)
  } else if (decimals != null) {
    return amount
      .mul(parseStringToBigNumber(tokenPrice.toFixed(10), 18).value)
      .div(BigNumber.from(10).pow(decimals))
  }
  return Zero
}

export function getTokenSymbolForPoolType(poolType: PoolTypes): string {
  if (poolType === PoolTypes.USD) {
    return "USDC"
  } else {
    return ""
  }
}

export async function getMulticallProvider(
  library?: Web3Provider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  chainId?: ChainId,
): Promise<MulticallProvider> {
  const ethcallProvider = new Provider() as MulticallProvider
  const provider = library ?? getNetworkLibrary()
  await ethcallProvider.init(provider)
  const address = getAddressUtil(MULTI_CALL_CONTRACT_ADDRESSES)
  ethcallProvider.multicallAddress = address
  return ethcallProvider
}

export async function getBlockNumber(): Promise<number | undefined> {
  const provider = getNetworkLibrary()
  return Promise.race([
    provider?.getBlockNumber(),
    new Promise<undefined>((resolve) => setTimeout(resolve, 5000, undefined)),
  ])
}

export function getChainId(): ChainId {
  if (process.env.REACT_APP_CHAIN_ID === undefined) {
    throw new Error("REACT_APP_CHAIN_ID not found.")
  }
  return parseInt(process.env.REACT_APP_CHAIN_ID)
}

export function toSignificant(value: string, digit: number): string {
  if (!/^\d+\.\d+$/.test(value)) {
    return value
  }
  const decimalIdx = value.indexOf(".")
  if (decimalIdx === -1) {
    return value
  }
  const [whole, decimals] = value.split(".")
  if (
    whole == "" ||
    whole == undefined ||
    decimals == "" ||
    decimals == undefined
  ) {
    return value
  }
  // assume starts with 0.xxxx or y.xxx
  if (whole !== "0") {
    return value.slice(
      0,
      decimalIdx + (digit > 0 ? digit + 1 : 0), // don't include decimal point if places = 0
    )
  }

  let firstNonZeroIndex = decimalIdx + 1
  for (let i = firstNonZeroIndex; i < value.length; i++) {
    if (value[i] !== "0") {
      firstNonZeroIndex = i
      break
    }
  }
  return value.slice(
    0,
    firstNonZeroIndex + (digit > 0 ? digit : 0), // don't include decimal point if places = 0
  )
}

export function formatApr(apr?: BigNumber) {
  return apr && apr.gt("0")
    ? apr.lt(parseUnits("0.01", 18))
      ? "<0.01%"
      : `${formatBNToString(apr, 18, 2)}%`
    : "-"
}

export function formatBoostedApr(
  apr?: BigNumber,
  ferroApr?: BigNumber,
  boostFactor = 1,
) {
  return formatApr(
    apr && ferroApr
      ? ferroApr
          .mul(parseUnits(String(boostFactor), 18))
          .div(parseUnits("1", 18))
          .add(apr)
          .sub(ferroApr)
      : undefined,
  )
}
