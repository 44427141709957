import { styled } from "@mui/material"
import FerroLogoImage from "../assets/FerroLogo.webp"

const FerroLogo = styled("div")(() => {
  return {
    width: "193px",
    height: "36px",
    display: "flex",
    backgroundImage: `url(${FerroLogoImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    color: "transparent",
    cursor: "pointer",
  }
})

export default FerroLogo
