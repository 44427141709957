import { Contract, ContractInterface } from "ethers"
import { getNetworkLibrary } from "src/connectors"
import FERROBAR_CONTRACT_ABI from "../constants/abis/ferroBar.json"
import FERRO_CONTRACT_ABI from "../constants/abis/ferro.json"
import FERROBOOST_CONTRACT_ABI from "../constants/abis/ferroBoost.json"
// import FERRO_FARM_BOOST_CONTRACT_ABI from "../constants/abis/ferroFarmBoost.json"
import { FerroBar } from "../../types/ethers-contracts/FerroBar"
// import { Multicall } from "../../types/ethers-contracts/Multicall"
import { Ferro } from "../../types/ethers-contracts/Ferro"
import { FerroFarm } from "../../types/ethers-contracts/FerroFarm"
// import { FerroFarmV2 } from "../../types/ethers-contracts/FerroFarmV2"
import { Rewarder } from "../../types/ethers-contracts/Rewarder"
import { FerroBoost } from "../../types/ethers-contracts/FerroBoost"
// import { FerroFarmBoost } from "types/ethers-contracts/FerroFarmBoost"
import FERROFARM_CONTRACT_ABI from "../constants/abis/ferroFarm.json"
// import FERROFARMV2_CONTRACT_ABI from "../constants/abis/ferroFarmV2.json"
import REWARDER_CONTRACT_ABI from "../constants/abis/rewarder.json"
// import MULTICALL_CONTRACT_ABI from "../constants/abis/Multicall.json"
import SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI from "../constants/abis/swapFlashLoanNoWithdrawFee.json"
import { memoize } from "lodash"
import META_SWAP_ABI from "../constants/abis/metaSwap.json"
import META_SWAP_DEPOSIT_ABI from "../constants/abis/metaSwapDeposit.json"

import { getAddress } from "./addressHelpers"
import {
  FERROBAR_CONTRACT_ADDRESSES,
  FERROBOOST_CONTRACT_ADDRESSES,
  // FERRO_FARM_BOOST_CONTRACT_ADDRESSES,
  FERROFARM_CONTRACT_ADDRESSES,
  // FERROFARMV2_CONTRACT_ADDRESSES,
  FERRO_CONTRACT_ADDRESSES,
  // MULTI_CALL_CONTRACT_ADDRESSES,
  POOLS_MAP,
  PoolName,
  isMetaPool,
  ChainId,
} from "src/constants"
import { SwapFlashLoanNoWithdrawFee } from "types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import { LpTokenUnguarded } from "types/ethers-contracts/LpTokenUnguarded"
import { MetaSwapDeposit } from "types/ethers-contracts/MetaSwapDeposit"
import { MetaSwap } from "types/ethers-contracts/MetaSwap"

export const getContractFromNetworkProvider = memoize(
  (address: string, ABI: ContractInterface): Contract => {
    return new Contract(address, ABI, getNetworkLibrary())
  },
)

export function getFerroBarContract(): FerroBar {
  return getContractFromNetworkProvider(
    getAddress(FERROBAR_CONTRACT_ADDRESSES),
    FERROBAR_CONTRACT_ABI,
  ) as FerroBar
}

export function getFerroContract(): Ferro {
  return getContractFromNetworkProvider(
    getAddress(FERRO_CONTRACT_ADDRESSES),
    FERRO_CONTRACT_ABI,
  ) as Ferro
}

export function getFerroBoostContract(): FerroBoost {
  return getContractFromNetworkProvider(
    getAddress(FERROBOOST_CONTRACT_ADDRESSES),
    FERROBOOST_CONTRACT_ABI,
  ) as FerroBoost
}

// export function getFerroFarmBoostContract(): FerroFarmBoost {
//   return getContractFromNetworkProvider(
//     getAddress(FERRO_FARM_BOOST_CONTRACT_ADDRESSES),
//     FERRO_FARM_BOOST_CONTRACT_ABI,
//   ) as FerroFarmBoost
// }

export function getFerroFarmContract(): FerroFarm {
  return getContractFromNetworkProvider(
    getAddress(FERROFARM_CONTRACT_ADDRESSES),
    FERROFARM_CONTRACT_ABI,
  ) as FerroFarm
}

// export function getFerroFarmV2Contract(): FerroFarmV2 {
//   return getContractFromNetworkProvider(
//     getAddress(FERROFARMV2_CONTRACT_ADDRESSES),
//     FERROFARMV2_CONTRACT_ABI,
//   ) as FerroFarmV2
// }

export function getRewarderContract(
  address: Record<ChainId, string>,
): Rewarder {
  return getContractFromNetworkProvider(
    getAddress(address),
    REWARDER_CONTRACT_ABI,
  ) as Rewarder
}

export function getSwapContract(
  poolName: PoolName,
): SwapFlashLoanNoWithdrawFee | MetaSwapDeposit {
  const pool = POOLS_MAP[poolName]
  if (isMetaPool(poolName)) {
    return getContractFromNetworkProvider(
      getAddress(pool.addresses),
      META_SWAP_DEPOSIT_ABI,
    ) as MetaSwapDeposit
  }
  return getContractFromNetworkProvider(
    getAddress(pool.addresses),
    SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI,
  ) as SwapFlashLoanNoWithdrawFee
}

export function getPoolSwapContract(
  poolName: PoolName,
): SwapFlashLoanNoWithdrawFee | MetaSwap {
  const pool = POOLS_MAP[poolName]
  if (isMetaPool(poolName) && pool.metaSwapAddresses) {
    return getContractFromNetworkProvider(
      getAddress(pool.metaSwapAddresses),
      META_SWAP_ABI,
    ) as MetaSwap
  }
  return getContractFromNetworkProvider(
    getAddress(pool.addresses),
    SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI,
  ) as SwapFlashLoanNoWithdrawFee
}

// export function getMulticallContract(): Multicall {
//   return getContractFromNetworkProvider(
//     getAddress(MULTI_CALL_CONTRACT_ADDRESSES),
//     MULTICALL_CONTRACT_ABI,
//   ) as Multicall
// }

export function getLpTokenContract(poolName: PoolName): LpTokenUnguarded {
  const pool = POOLS_MAP[poolName]
  return getContractFromNetworkProvider(
    getAddress(pool.lpToken.addresses),
    LPTOKEN_UNGUARDED_ABI,
  ) as LpTokenUnguarded
}
