import { useQueries } from "@tanstack/react-query"
import { SUPPORTED_TOKENS } from "src/constants"
import { TokenPricesUSD } from "../state/application"

const priceAPI = process.env.REACT_APP_PRICE_API!

interface PriceAPIResponse {
  data: {
    price: number
    generatedTimeMs: number
  }
}

export default function useTokenPricesUSD() {
  const prices = useQueries({
    queries: SUPPORTED_TOKENS.map(({ symbol }) => {
      return {
        queryKey: ["fetchPrices", symbol],
        queryFn: async (): Promise<Record<string, number>> => {
          const result = await fetch(`${priceAPI}/${symbol}`)
          if (result.ok) {
            const response = (await result.json()) as PriceAPIResponse
            return { [symbol]: response.data.price }
          } else {
            throw new Error(result.statusText)
          }
        },
      }
    }),
  })

  const preConfig: TokenPricesUSD = { USDC: 1, USTC: 1, MAI: 1, USC: 1 }

  const aggregated = prices.reduce((acc, result) => {
    if (result.isSuccess) {
      return { ...acc, ...result.data }
    }
    return acc
  }, preConfig)

  aggregated["WCRO"] = aggregated["CRO"]

  return aggregated
}
