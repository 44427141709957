import {
  ChainId,
  NetworkContextName,
  SUPPORTED_WALLETS,
  WALLET_CONNECTOR,
} from "../constants"
import { useEffect } from "react"

import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactContextInterface } from "@web3-react/core/dist/types"
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core"
import { useSelector, useDispatch } from "react-redux"
import { isMobile } from "@deficonnect/utils"
import { AppState } from "src/state"
import { updateWalletConnector } from "src/state/user"
import isBraveBrowser from "src/utils/isBraveBrowser"

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & {
  chainId?: ChainId
} {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect(): void {
  const { activate } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const dispatch = useDispatch()
  const connectedWallet = useSelector(
    (state: AppState) => state.user.connectedWallet,
  )
  // const [tried, setTried] = useState(false)

  useEffect(() => {
    async function connect() {
      if (connectedWallet) {
        const wallet = SUPPORTED_WALLETS[connectedWallet]
        if (wallet) {
          await activate(wallet.connector, undefined, true).catch((e) => {
            console.error(e)
            void activate(wallet.connector)
          })
        }
      } else if (isMobile() && window.ethereum) {
        const connectorKey = isBraveBrowser
          ? WALLET_CONNECTOR.BRAVEWALLET
          : WALLET_CONNECTOR.METAMASK
        const wallet = SUPPORTED_WALLETS[connectorKey]
        if (wallet) {
          await activate(wallet.connector, undefined, true).catch((e) => {
            console.error(e)
            void activate(wallet.connector)
          })

          dispatch(updateWalletConnector(connectorKey))
        }
      }
    }
    void connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
