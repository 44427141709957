import "./Banner.scss"
import React from "react"
import { Box, styled } from "@mui/material"
import { useVenoStrategyApy } from "../../hooks/useVenoApy"
import venoBgImage from "../../assets/veno.webp"
import FerroLogoImage from "../../assets/FerroLogo.webp"
import VenoLogoImage from "../../assets/VenoLogo.webp"
import Text from "../Text"
import Button from "../Button"

const BgContainer = styled("div")(() => {
  return {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundImage: `url(${venoBgImage})`,
    height: "100%",
    width: "100%",
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 150%",
    opacity: 0.5,
  }
})

const FerroLogo = styled("div")(() => {
  return {
    width: "105px",
    height: "24px",
    display: "flex",
    backgroundImage: `url(${FerroLogoImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    color: "transparent",
  }
})

const VenoLogo = styled("div")(() => {
  return {
    width: "100px",
    height: "24px",
    display: "flex",
    backgroundImage: `url(${VenoLogoImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    color: "transparent",
  }
})

const VenoStategyBanner = () => {
  const { data: venoApy } = useVenoStrategyApy()
  const apyText =
    venoApy && venoApy.apy
      ? ` at up to APY ${(venoApy.apy * 100).toFixed(2)}%`
      : ""
  return (
    <Box className="banner-container veno">
      <BgContainer />
      <Box zIndex={1}>
        <Box display="flex" gap="24px" mb="12px">
          <FerroLogo />
          <VenoLogo />
        </Box>
        <h3>Liquidity Strategy</h3>
        <Box mt="12px" mb="12px">
          <Text>
            Automatically stake, harvest, and reinvest tokens to earn
            compounding rewards{apyText}.
          </Text>
        </Box>
        <Box display="flex" gap="24px">
          <a
            href="https://docs.veno.finance/veno-finance/protocol/liquidity-strategy"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", zIndex: 2 }}
          >
            <Button sx={{ boxSizing: "border-box" }} size="small">
              Learn More
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default VenoStategyBanner
