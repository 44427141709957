import { gql, request } from "graphql-request"
import { formatUnits } from "@ethersproject/units"

const BOOST_GRAPH_URL = process.env.REACT_APP_BOOST_URL!

interface BoostResponse {
  histories: {
    id: string
    date: number
    timeframe: string
    totalBoostAmount: string
  }[]
}

const GET_XFER_LOCKED_IN_VAULT = gql`
  query getTotalXferInVault {
    histories(skip: 0, first: 1, orderBy: id, orderDirection: desc) {
      id
      date
      timeframe
      totalBoostAmount
    }
  }
`

const fetchBoost = async (): Promise<{
  currentBoostAmount: string
  error?: boolean
}> => {
  try {
    const boostResponse = await request<BoostResponse>(
      BOOST_GRAPH_URL,
      GET_XFER_LOCKED_IN_VAULT,
    )

    return {
      currentBoostAmount: boostResponse?.histories?.[0]?.totalBoostAmount,
      error: true,
    }
  } catch (error) {
    console.error("Failed to fetch boost from subgraph", error)
    return { currentBoostAmount: "0", error: true }
  }
}

export default async function getBoostStats(): Promise<number> {
  const { currentBoostAmount } = await fetchBoost()
  return parseInt(formatUnits(currentBoostAmount, 18))
}
