import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AppThunk } from "../types"
import fetchFerroBarAllowance, {
  fetchFerroBarBalance,
  fetchFerroBarTotalSupply,
} from "./fetchFerroBarUser"
import { fetchFerroBoostPoolData } from "./fetchFerroBoostPool"
import fetchFerroBoostAllowance, {
  fetchFerroBoostUserData,
} from "./fetchFerroBoostUser"
import {
  BarHistoryResponse,
  SerializedBarData,
  SerializedBoostData,
  SerializedBoostPoolData,
} from "./types"
import { BigNumber } from "ethers"

export interface XFerState {
  openedStakeModal: { isUnstaking: boolean } | null
  openedDepositModal: {
    defaultSelectedPool: number | null
    lockAmount?: BigNumber
    harvest?: () => Promise<void>
  } | null
  openedUpgradeModal: {
    stakeIds: number[]
    poolId: number
    amount: string
    lastUnlockTime: number
  } | null
  openedWithdrawModal: { stakeIds: number[] } | null
  openedNftStakeModal: { nftIds: string[] } | null
  barData: SerializedBarData
  boostData: SerializedBoostData
  boostPoolData: SerializedBoostPoolData
  barDailySnapshots: BarHistoryResponse
}

export const initialState: XFerState = {
  openedStakeModal: null,
  openedDepositModal: null,
  openedUpgradeModal: null,
  openedWithdrawModal: null,
  openedNftStakeModal: null,
  barData: {
    allowance: "0",
    stakedBalance: "0",
    totalSupply: "0",
  },
  boostData: {
    allowance: "0",
    pendingFer: "0",
    stakes: [],
  },
  boostPoolData: {
    poolWeight: "0",
    totalSupply: "0",
    poolInfo: [],
  },
  barDailySnapshots: { barDailySnapshots: [] },
}

export const fetchFerroBarUserDataAsync =
  (account: string | null | undefined): AppThunk =>
  async (dispatch) => {
    const allowance = account ? await fetchFerroBarAllowance(account) : "0"
    const stakedBalance = await fetchFerroBarBalance()
    const totalSupply = await fetchFerroBarTotalSupply()

    const userData: SerializedBarData = {
      allowance,
      stakedBalance,
      totalSupply,
    }

    dispatch(actions.setFerroBarUserData(userData))
  }

export const fetchFerroBoostUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchFerroBoostAllowance(account)
    const data = await fetchFerroBoostUserData(account)

    const [pendingFer, stakes] = data

    const userData: SerializedBoostData = {
      allowance,
      pendingFer,
      stakes,
    }

    dispatch(actions.setFerroBoostUserData(userData))
  }

export const fetchFerroBoostPoolDataAsync =
  (): AppThunk => async (dispatch, getState) => {
    const totalAllocPoint = getState().application.totalAllocPoint

    const data = await fetchFerroBoostPoolData(totalAllocPoint)
    const [totalSupply, poolWeight, ...poolInfo] = data
    const poolData: SerializedBoostPoolData = {
      totalSupply,
      poolWeight,
      poolInfo,
    }
    dispatch(actions.setFerroBoostPoolData(poolData))
  }

const xFerSlice = createSlice({
  name: "xFer",
  initialState,
  reducers: {
    setFerroBarUserData: (state, action: PayloadAction<SerializedBarData>) => {
      const userBarData = action.payload
      state.barData = userBarData
    },
    setFerroBoostUserData: (
      state,
      action: PayloadAction<SerializedBoostData>,
    ) => {
      const userBoostData = action.payload
      state.boostData = userBoostData
    },
    setFerroBoostPoolData: (
      state,
      action: PayloadAction<SerializedBoostPoolData>,
    ) => {
      const data = action.payload
      state.boostPoolData = data
    },
    setOpenedStakeModal: (
      state,
      action: PayloadAction<XFerState["openedStakeModal"]>,
    ) => {
      const data = action.payload
      state.openedStakeModal = data
    },
    setOpenedDepositModal: (
      state,
      action: PayloadAction<XFerState["openedDepositModal"]>,
    ) => {
      const data = action.payload
      state.openedDepositModal = data
    },
    setOpenedUpgradeModal: (
      state,
      action: PayloadAction<XFerState["openedUpgradeModal"]>,
    ) => {
      const data = action.payload
      state.openedUpgradeModal = data
    },
    setOpenedWithdrawModal: (
      state,
      action: PayloadAction<XFerState["openedWithdrawModal"]>,
    ) => {
      const data = action.payload
      state.openedWithdrawModal = data
    },
    setOpenedNftStakeModal: (
      state,
      action: PayloadAction<XFerState["openedNftStakeModal"]>,
    ) => {
      const data = action.payload
      state.openedNftStakeModal = data
    },
    setBarDailySnapshots: (
      state,
      action: PayloadAction<BarHistoryResponse>,
    ) => {
      const data = action.payload
      state.barDailySnapshots = data
    },
  },
})

export const actions = xFerSlice.actions

export default xFerSlice.reducer
