import { Theme, useMediaQuery } from "@mui/material"

export const useIsMobile = () => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
}

export const useIsLaptop = () => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
}

export const useIsLargeScreen = () => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
}

export const useIsExLargeScreen = () => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"))
}
