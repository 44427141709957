// ---------------------------------------------------------------------

import { TypographyOptions } from "@mui/material/styles/createTypography"

function pxToRem(value: number): string {
  return `${value / 16}rem`
}

const ROBOTO_FAMILY = ['"roboto"', "san-serif"].join(",")
const EUROSTILE_EXTD_FAMILY = [
  '"eurostile-extended", "roboto", sans-serif',
].join(",")

declare module "@mui/material/styles/createTypography" {}

const typography: TypographyOptions = {
  fontFamily: ROBOTO_FAMILY,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightBold: 600,
  h1: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(50),
    lineHeight: 60 / 50,
  },
  h2: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(40),
    lineHeight: 52 / 40,
  },
  h3: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(29),
    lineHeight: 34.8 / 29,
  },
  h4: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(20),
    lineHeight: 26 / 20,
  },
  h5: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(18),
    lineHeight: 26 / 18,
  },
  h6: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(17),
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: pxToRem(12),
    lineHeight: 16 / 12,
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: 22 / 16,
  },
  body2: {
    fontSize: pxToRem(12),
    lineHeight: 18 / 12,
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: 16 / 12,
  },
  button: {
    fontFamily: EUROSTILE_EXTD_FAMILY,
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: 1,
  },
}

export default typography
